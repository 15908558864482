import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import React, { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  GetAllOrderTypeLookup,
  UploadFileFullfilable,
  UploadFileRegular,
} from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import { errorNotification } from "../../../utilities/toast";
import RHFCustomAutocompleteField from "../../../.reUseableComponents/TextField/RHFCustomAutocompleteField";
import ModalButtonComponent from "../../../.reUseableComponents/Buttons/ModalButtonComponent";
import ModalComponent from "../../../.reUseableComponents/Modal/ModalComponent";
import { purple } from "../../../utilities/helpers/Helpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function PlaceOrderModal(props) {
  const [allOrderTypeLookup, setAllOrderTypeLookup] = useState([]);
  const [xlsxFile, setXlsxFile] = useState(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const {
    open,
    setOpen,
    setOrderData,
    storesForSelection,
    setErrorsList,
    countryId,
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    control,
  } = useForm();
  useWatch({
    name: "orderType",
    control,
  });
  useWatch({
    name: "store",
    control,
  });

  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const handleClose = () => {
    setOpen(false);
  };
  const load = (data) => {
    setErrorsList([]);
    if (!xlsxFile) {
      errorNotification(LanguageReducer?.languageType?.FILE_REQURED_TOAST);
      return;
    }
    setIsLoading(true);
    setOrderData([]);
    // console.log("data", data);
    const formData = new FormData();
    formData.append("File", xlsxFile);
    formData.append("CountryId", countryId);
    if (data.orderType.orderTypeId === 1) {
      UploadFileRegular(formData)
        .then((res) => {
          setIsLoading(false);
          if (res?.data?.result && res?.data?.result?.length > 0) {
            let updatedItems = res.data.result.map((item, index) => ({
              ...item,
              actualAmmount: item?.amount,
              index: index + 1,
            }));
            setOrderData(updatedItems);
          }
          if (res?.data?.errors?.FormatException) {
            console.log(
              "res?.data?.errors?.FormatException",
              res?.data?.errors?.FormatException
            );
            for (
              let j = 0;
              j < res?.data?.errors?.FormatException.length;
              j++
            ) {
              errorNotification(res?.data?.errors?.FormatException[j]);
            }
            return;
          }
          if (res?.data?.errors?.Exception) {
            for (let j = 0; j < res?.data?.errors?.Exception.length; j++) {
              errorNotification(res?.data?.errors?.Exception[j]);
            }
            return;
          }
          if (res?.data?.errors?.InvalidParameter) {
            const errorMessages = JSON.parse(
              res?.data?.errors?.InvalidParameter
            );
            console.log("errorMessages", errorMessages);
            setErrorsList(errorMessages);
            for (let index = 0; index < errorMessages.length; index++) {
              if (!errorMessages[index].IsSuccessed) {
                errorNotification(
                  `Error at row ${index}: ${errorMessages[index].Msg} `
                );
              }
            }
          }
          setValue("orderType", null);
          setValue("store", null);
          setXlsxFile(null);
          fileInputRef.current.value = "";
          reset();
          setOpen(false);
        })
        .catch((e) => {
          setIsLoading(false);
          errorNotification(
            LanguageReducer?.languageType
              ?.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_TOAST
          );
          console.log("e", e);
        });
    } else {
      formData.append("StoreId", data.store?.storeId);
      UploadFileFullfilable(formData)
        .then((res) => {
          // console.log("res", res);
          // props.setProductFile(res.data.result.url);
          setIsLoading(false);
          if (res?.data?.result && res?.data?.result?.length > 0) {
            let updatedItems = res.data.result.map((item, index) => ({
              ...item,
              actualAmmount: item?.amount,
              index: index + 1,
            }));
            // setItems(updatedItems);
            setOrderData(updatedItems);
          }

          if (res?.data?.errors?.FormatException) {
            console.log(
              "res?.data?.errors?.FormatException",
              res?.data?.errors?.FormatException
            );
            for (
              let j = 0;
              j < res?.data?.errors?.FormatException.length;
              j++
            ) {
              errorNotification(res?.data?.errors?.FormatException[j]);
            }
            return;
          }
          if (res?.data?.errors?.Exception) {
            for (let j = 0; j < res?.data?.errors?.Exception.length; j++) {
              errorNotification(res?.data?.errors?.Exception[j]);
            }
            return;
          }
          if (res?.data?.errors?.InvalidParameter) {
            const errorMessages = JSON.parse(
              res?.data?.errors?.InvalidParameter
            );
            console.log("errorMessages", errorMessages);
            setErrorsList(errorMessages);
            for (let index = 0; index < errorMessages.length; index++) {
              if (!errorMessages[index].IsSuccessed) {
                errorNotification(
                  `Error at row ${index}: ${errorMessages[index].Msg} `
                );
              }
            }
          }
          setValue("orderType", null);
          setValue("store", null);
          setXlsxFile(null);
          fileInputRef.current.value = "";
          reset();
          setOpen(false);
        })
        .catch((e) => {
          setIsLoading(false);
          errorNotification(
            LanguageReducer?.languageType
              ?.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_TOAST
          );
          console.log("e", e);
        });
    }
    // handleClose()
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (
      file &&
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setXlsxFile(file);
      // File is a valid .xlsx file, you can proceed with further operations
    } else {
      errorNotification(LanguageReducer?.languageType?.INVALID_FILE_TYPE_TOAST);
      // Invalid file format, display an error message or handle accordingly
    }
  };
  const getAllOrderTypeLookup = async () => {
    try {
      const response = await GetAllOrderTypeLookup();
      setAllOrderTypeLookup(response.data.result);
    } catch (error) {
      console.error("Error fetching GetAllOrderTypeLookup:", error.response);
    }
  };

  useEffect(() => {
    getAllOrderTypeLookup();
  }, []);
  return (
    <ModalComponent
      open={open}
      onClose={() => {
        setValue("orderType", null);
        setValue("store", null);
        setXlsxFile(null);
        fileInputRef.current.value = "";
        handleClose();
      }}
      maxWidth="sm"
      title={LanguageReducer?.languageType?.PLACE_ORDER_FROM_EXCEL_FILE_TEXT}
      actionBtn={
        <ModalButtonComponent
          title={"Create Order"}
          bg={purple}
          type="submit"
          loading={isLoading}
        />
      }
      component={"form"}
      onSubmit={handleSubmit(load)}
    >
      <Grid container spacing={2} sx={{ mt: "5px" }}>
        <Grid item md={12} sm={12} xs={12}>
          <InputLabel required sx={styleSheet.inputLabel}>
            {LanguageReducer?.languageType?.SELECT_ORDER_TYPE_TEXT}
          </InputLabel>
          <RHFCustomAutocompleteField
            defautValue={{
              orderTypeId: 0,
              orderTypeName: "Select Please",
            }}
            name="orderType"
            control={control}
            options={allOrderTypeLookup}
            getOptionLabel={(option) => option?.orderTypeName}
            requiredError={LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT}
            value={getValues("orderType")}
            errors={errors}
            onChange={(event, newValue) => {
              const resolvedId = newValue ? newValue : null;
              setValue("orderType", resolvedId);
            }}
            rules={{
              required: {
                value: true,
                message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
              },
              validate: {
                positive: (v) => parseInt(v.orderTypeId) > 0,
              },
            }}
            required
          />
        </Grid>

        {getValues("orderType")?.orderTypeId === 2 ? (
          <Grid item md={12} sm={12} xs={12}>
            <InputLabel required sx={styleSheet.inputLabel}>
              {LanguageReducer?.languageType?.SELECT_STORE_TEXT}
            </InputLabel>
            <RHFCustomAutocompleteField
              name="store"
              control={control}
              options={storesForSelection}
              getOptionLabel={(option) => option?.storeName}
              requiredError={LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT}
              value={getValues("store")}
              errors={errors}
              onChange={(event, newValue) => {
                const resolvedId = newValue ? newValue : null;
                setValue("store", resolvedId);
              }}
              rules={{
                required: {
                  value: true,
                  message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                },
                validate: {
                  positive: (v) => parseInt(v.storeId) > 0,
                },
              }}
              required
            />
          </Grid>
        ) : null}
      </Grid>

      <input
        style={{ paddingTop: "20px" }}
        type="file"
        accept=".xlsx"
        onChange={handleFileUpload}
        ref={fileInputRef}
      />
    </ModalComponent>
  );
}
export default PlaceOrderModal;
