import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Badge } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import * as React from "react";
// import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { styleSheet } from "../../../assets/styles/style";
import DraggableTab from "./DraggableTab";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

export default function DraggableTabsList(props) {
  //console.log(props);
  const _renderTabList = (droppableProvided) => (
    <TabList
      onChange={props?.handleChange}
      aria-label="Draggable Tabs"
      variant="scrollable"
      sx={{
        ...styleSheet.customTabsUI,
        justifyContent: "space-around",
      }}
    >
      {props.tabs.map((tab, index) => {
        //console.log(tab.count);
        const child = (
          <Tab
            sx={{
              fontSize: "12px",
              minWidth: "60px",
              paddingLeft: "15px",
              paddingRight: "25px",
              minHeight: "33px !important",
              fontWeight: 600,
              width: "max-content",
              cursor: "pointer !important",
            }}
            label={
              <Box
                display={"-webkit-inline-box"}
                gap={3}
                justifyContent={"space-between"}
              >
                <Badge
                  badgeContent={String(tab.count || 0)}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "rgb(86, 58, 213)",
                      // fontSize: '8px',
                      width: "17px",
                      minWidth: "17px",
                      height: "17px",
                      color: "white",
                      padding: "0 4px",
                      right: -8,
                      top: 7,
                    },
                  }}
                ></Badge>
                <Box>{tab.label}</Box>
              </Box>
            }
            value={tab[props.valueParam] || ""}
            key={index}
          />
        );

        return (
          <DraggableTab
            label={
              <Box display={"flex"} gap={3} justifyContent={"space-between"}>
                <Badge
                  badgeContent={String(tab.count || 0)}
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "rgb(86, 58, 213)",
                      // fontSize: '8px',
                      width: "17px",
                      minWidth: "17px",
                      height: "17px",
                      color: "white",
                      padding: "0 4px",
                      right: -8,
                      top: 7,
                    },
                  }}
                ></Badge>
                <Box>{tab.label}</Box>
              </Box>
            }
            value={tab[props.valueParam] || ""}
            index={index}
            key={index}
            isDragDisabled={true}
            child={child}
          />
        );
      })}
      {droppableProvided ? droppableProvided.placeholder : null}
    </TabList>
  );

  const _renderTabListWrappedInDroppable = () => (
    <DragDropContext onDragEnd={props.onDragEnd}>
      <div>
        <Droppable droppableId="1" direction="horizontal" isDropDisabled={true}>
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              {...droppableProvided.droppableProps}
            >
              {_renderTabList(droppableProvided)}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={props.value}>
        <Box sx={{ borderColor: "divider" }}>
          <Stack direction="column">{_renderTabListWrappedInDroppable()}</Stack>
        </Box>
        {props.tabs.map((tab, index) => (
          <TabPanel value={tab.value} key={index}>
            {tab.content}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}
