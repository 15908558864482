import React, { useEffect, useState } from "react";
import {
  Card,
  Dialog,
  DialogContent,
  Box,
  Chip,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
  Paper,
  InputLabel,
  TextField,
  MenuItem,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { styleSheet } from "../../../assets/styles/style";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import {
  GetChannelListByStoreIdForSelection,
  SaleChannelOrderPreProcessor,
} from "../../../api/AxiosInterceptors";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import { LoadingButton } from "@mui/lab";
import UtilityClass from "../../../utilities/UtilityClass";
import CustomAutocompleteField from "../../../.reUseableComponents/TextField/CustomAutocompleteField";
import CustomReactDatePickerInput from "../../../.reUseableComponents/TextField/CustomReactDatePickerInput";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function SaleChannelSyncModel(props) {
  let { open, setOpen, allStores, setAllOrders, resetRowRef } = props;
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const [store, setStore] = useState({
    storeId: 0,
    storeName: "Select Please",
  });
  const [storeChannel, setStoreChannel] = useState({
    id: 0,
    text: "Select Please",
  });
  const handleSubmitClick = () => {
    if (!store?.storeId || store?.storeId == 0) {
      errorNotification("Please select store");
      return false;
    }
    if (!storeChannel?.id || storeChannel?.id == 0) {
      errorNotification("Please select store channel");
      return false;
    }
    setIsLoading(true);
    let param = {
      storeId: store?.storeId,
      saleChannelConfigId: storeChannel?.id,
      createdFrom: filterModal.createdFrom,
      createdTo: filterModal.createdTo,
    };
    SaleChannelOrderPreProcessor(param)
      .then((res) => {
        setIsLoading(false);
        if (!res.data.isSuccess) {
          UtilityClass.showErrorNotificationWithDictionary(res.data?.errors);
        } else {
          setAllOrders(res.data.result);
          setOpen(false);
        }
      })
      .catch((e) => {
        if (!e.response.data.isSuccess) {
          UtilityClass.showErrorNotificationWithDictionary(
            e.response.data.errors
          );
        } else {
          errorNotification(
            LanguageReducer?.languageType
              ?.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_TOAST
          );
        }
        setIsLoading(false);

        console.log("e", e);
      });
  };
  const [allStoreChannl, setAllStoreChannl] = useState([]);
  useEffect(() => {
    if (store?.storeId && store?.storeId > 0) {
      getChannelListByStoreIdForSelection();
    }
  }, [store]);
  let getChannelListByStoreIdForSelection = async () => {
    let res = await GetChannelListByStoreIdForSelection(store?.storeId);
    if (res.data.result !== null) {
      setAllStoreChannl(res.data.result);
    }
  };
  const [filterModal, setFilterModal] = useState({
    createdFrom: new Date(),
    createdTo: new Date(),
  });
  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="sm"
      sx={styleSheet.modelMainClassWithImage}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          overflowY: "inherit",
        },
      }}
    >
      <DialogTitle sx={styleSheet.outScanHeading}>
        Sync Sale channel Order (s)
      </DialogTitle>
      <DialogContent
        sx={{ ...styleSheet.modelContentArea, marginBottom: "10px" }}
      >
        <Grid container spacing={2}>
          <Grid item md={12} sm={12}>
            {" "}
            <InputLabel sx={styleSheet.inputLabel} required>
              From Date
            </InputLabel>
            <CustomReactDatePickerInput
              value={filterModal.createdFrom}
              onClick={(date) =>
                setFilterModal((prevState) => ({
                  ...prevState,
                  createdFrom: date,
                }))
              }
            />
          </Grid>
          <Grid item md={12} sm={12}>
            {" "}
            <InputLabel sx={styleSheet.inputLabel} required>
              To Date
            </InputLabel>
            <CustomReactDatePickerInput
              value={filterModal.createdTo}
              onClick={(date) =>
                setFilterModal((prevState) => ({
                  ...prevState,
                  createdTo: date,
                }))
              }
              minDate={filterModal.createdFrom}
              disabled={!filterModal.createdFrom ? true : false}
            />
          </Grid>
          <Grid item md={12} sm={12}>
            {" "}
            <InputLabel sx={styleSheet.inputLabel} required>
              Select Store
            </InputLabel>
            <CustomAutocompleteField
              name="store"
              options={allStores}
              value={store}
              getOptionLabel={(option) => option.storeName}
              onChange={(e, newValue) => {
                const resolvedId = newValue ? newValue : null;
                setStore(resolvedId);
              }}
              size={"md"}
            />
          </Grid>
          {store?.storeId && store?.storeId > 0 ? (
            <Grid item md={12} sm={12}>
              {" "}
              <InputLabel sx={styleSheet.inputLabel} required>
                Select Store Channel
              </InputLabel>
              <CustomAutocompleteField
                name="storeChannel"
                options={allStoreChannl}
                value={storeChannel}
                getOptionLabel={(option) => option.text}
                onChange={(e, newValue) => {
                  const resolvedId = newValue ? newValue : null;
                  setStoreChannel(resolvedId);
                }}
                size={"md"}
              />
            </Grid>
          ) : null}
          {/* <Grid item md={12} sm={12} sx={{ textAlign: "right" }}>
            {" "}
            <FormControlLabel
              sx={{ margin: "0px" }}
              control={
                <Checkbox
                  sx={{
                    color: "#563AD5",
                    "&.Mui-checked": {
                      color: "#563AD5",
                    },
                  }}
                  checked={true}
                  onChange={(e) => console.log(e.target.value)}
                  edge="start"
                  disableRipple
                  defaultChecked
                />
              }
              label={"Allow to display in Sale Channel"}
            />
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          sx={{
            ...styleSheet.modalDismissButton,
            borderBottomLeftRadius: "20px !important",
          }}
          onClick={handleClose}
        >
          {LanguageReducer?.languageType?.DISMISS_TEXT}
        </Button>
        <LoadingButton
          fullWidth
          loading={isLoading}
          variant="contained"
          sx={{
            ...styleSheet.modalCarrierSubmitButton,
            borderBottomRightRadius: "20px !important",
          }}
          onClick={handleSubmitClick}
        >
          {"Sync Order"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default SaleChannelSyncModel;
