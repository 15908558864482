import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Slide,
  Stack,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DataGrid } from "@mui/x-data-grid";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  DownloadInventoryHistorySummaryExcel,
  ExcelExportProductStockHistory,
  GetAllReasons,
  GetProductStockHistoryByStockId,
} from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import { errorNotification } from "../../../utilities/toast";
import CustomReactDatePickerInput from "../../../.reUseableComponents/TextField/CustomReactDatePickerInput";
import RHFAutocompleteField from "../../../.reUseableComponents/TextField/RHFAutocompleteField";
import CustomAutocompleteField from "../../../.reUseableComponents/TextField/CustomAutocompleteField";
import UtilityClass from "../../../utilities/UtilityClass";
import {
  ClipboardIcon,
  CodeBox,
  centerColumn,
} from "../../../utilities/helpers/Helpers";
import useDateRangeHook from "../../../.reUseableComponents/CustomHooks/useDateRangeHook";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function StockHistoryModal({ open, handleClose, inventoryItem }) {
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    resetDates,
    startDateFormated,
    endDateFormated,
  } = useDateRangeHook();

  console.log("inventoryItem", inventoryItem);

  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const gridApiRef = useRef(null);
  const [allReasons, setAllReasons] = useState([]);
  const [stockHistory, setStockHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSummary, setIsLoadingSummary] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);
  const [isExcelExport, setIsExcelExport] = useState(false);
  const [reasonId, setReasonId] = useState(0);

  const [quantity, setQuantity] = useState(0);

  const columns = [
    {
      field: "SKU",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.SKU_TEXT}
        </Box>
      ),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            <CodeBox title={row.SKU} />
            <ClipboardIcon text={row.SKU} />
          </>
        );
      },
    },
    {
      field: "Reason",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.PRODUCT_TEXT}{" "}
          {LanguageReducer?.languageType?.REASON_TEXT}
        </Box>
      ),
      flex: 1,
    },
    {
      field: "Comment",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.COMMENT_TEXT}
        </Box>
      ),
      flex: 1,
    },
    {
      field: "CreatedOn",
      ...centerColumn,
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.CREATED_ON_TEXT}
        </Box>
      ),
      flex: 1,
      renderCell: (params) => (
        <Box>
          {UtilityClass.convertUtcToLocalAndGetDate(params.row?.CreatedOn)}
        </Box>
      ),
    },
    {
      field: "PreviousQuantity",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.PREVIOUS_QUANTITY_TEXT}
        </Box>
      ),
      flex: 1,
      ...centerColumn,
      renderCell: ({ row }) => {
        return (
          <>
            <CodeBox title={row.PreviousQuantity} />
          </>
        );
      },
    },
    {
      field: "NewQuantity",
      headerName: <Box sx={{ fontWeight: "600" }}> {"New Quantity"}</Box>,
      flex: 1,
      ...centerColumn,
      renderCell: ({ row }) => {
        return (
          <>
            <CodeBox title={row.NewQuantity} />
          </>
        );
      },
    },
  ];

  const handleGetAllReasons = async () => {
    try {
      const response = await GetAllReasons();
      console.log("response of api of reasons", response);
      setAllReasons(response.data.result);
    } catch (error) {
      console.error("Error fetching reasons:", error.response);
    }
  };
  const downloadInventoryHistorySummaryExcel = async () => {
    setIsLoadingSummary(true);
    try {
      let params = {
        filterModel: {
          createdFrom: startDateFormated ? startDateFormated : null,
          createdTo: endDateFormated ? endDateFormated : null,
          start: 0,
          length: 10000,
          search: "",
          sortDir: "desc",
          sortCol: 0,
        },
        productStockId: inventoryItem?.ProductStockId,
        reasonId: reasonId?.id,
      };
      console.log("params", params);
      const response = await DownloadInventoryHistorySummaryExcel(params);
      console.log(
        "response of api of downloadInventoryHistorySummaryExcel",
        response
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `Summary_${Date.now()}.xlsx`;
      link.click();
      URL.revokeObjectURL(downloadUrl);
      setIsLoadingSummary(false);
    } catch (error) {
      console.error(
        "Error fetching downloadInventoryHistorySummaryExcel:",
        error.response
      );
      setIsLoadingSummary(false);
    }
  };

  const excelExportProductStockHistory = async () => {
    setIsExcelExport(true);
    try {
      let params = {
        filterModel: {
          createdFrom: startDateFormated ? startDateFormated : null,
          createdTo: endDateFormated ? endDateFormated : null,
          start: 0,
          length: 10000,
          search: "",
          sortDir: "desc",
          sortCol: 0,
        },
        productStockId: inventoryItem?.ProductStockId,
        reasonId: reasonId?.id,
      };
      console.log("params", params);
      const response = await ExcelExportProductStockHistory(params);
      setIsExcelExport(false);

      console.log(
        "response of api of ExcelExportProductStockHistory",
        response
      );
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const downloadUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `History_${Date.now()}.xlsx`;
      link.click();

      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error(
        "Error fetching ExcelExportProductStockHistory:",
        error.response
      );
    }
  };

  const getProductStockHistoryByStockId = async (data) => {
    setIsLoading(true);
    setIsLoadingHistory(true);

    let params = {
      filterModel: {
        createdFrom: startDateFormated ? startDateFormated : null,
        createdTo: endDateFormated ? endDateFormated : null,
        start: 0,
        length: 10000,
        search: "",
        sortDir: "desc",
        sortCol: 0,
      },
      productStockId: inventoryItem?.ProductStockId,
      reasonId: reasonId?.id,
    };
    console.log("params", params);
    const response = await GetProductStockHistoryByStockId(params);
    console.log("response of api of GetProductStockHistoryByStockId", response);

    if (response.data.result) setStockHistory(response.data.result.list);
    setIsLoading(false);
    setIsLoadingHistory(false);
  };

  useEffect(() => {
    handleGetAllReasons();
    getProductStockHistoryByStockId();
  }, []);
  useEffect(() => {
    if (allReasons && allReasons.length > 0) {
      setReasonId(allReasons[0]);
    }
  }, [allReasons]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      scroll={"body"}
      onClose={handleClose}
      maxWidth="xl"
      sx={styleSheet.modelMainClassWithImage}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={styleSheet.allProductionHeading}>
        {LanguageReducer?.languageType?.STOCK_HISTORY_TEXT}
      </DialogTitle>
      <DialogContent sx={{ ...styleSheet.modelContentArea }}>
        <DialogContentText id="alert-dialog-slide-description">
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <InputLabel sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.CREATED_FROM_DATE_TEXT}
              </InputLabel>

              <CustomReactDatePickerInput
                value={startDate}
                onClick={(date) => setStartDate(date)}
                size="small"
                isClearable
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <InputLabel sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.CREATED_TO_DATE_TEXT}
              </InputLabel>
              <CustomReactDatePickerInput
                value={endDate}
                onClick={(date) => setEndDate(date)}
                width="180px"
                size="small"
                minDate={startDate}
                disabled={!startDate ? true : false}
                isClearable
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <InputLabel required sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.REASON_TEXT}
              </InputLabel>
              <CustomAutocompleteField
                name="reason"
                options={allReasons}
                value={reasonId}
                getOptionLabel={(option) => option?.text}
                onChange={(e, value) => {
                  setReasonId(value);
                }}
                size="md"
              />
            </Grid>
            <Grid item xs={12}>
              <div>
                <Stack direction="row" justifyContent="flex-end">
                  <LoadingButton
                    loading={isLoading}
                    variant="contained"
                    sx={{ ...styleSheet.addCarrierButton, margin: "10px" }}
                    type="submit"
                    onClick={getProductStockHistoryByStockId}
                  >
                    {LanguageReducer?.languageType?.LOAD_TEXT}
                  </LoadingButton>
                  <LoadingButton
                    loading={isLoadingSummary}
                    variant="contained"
                    sx={{ ...styleSheet.addCarrierButton, margin: "10px" }}
                    onClick={downloadInventoryHistorySummaryExcel}
                  >
                    {LanguageReducer?.languageType?.EXPORT_SUMMARY_TEXT}
                  </LoadingButton>
                  <LoadingButton
                    loading={isExcelExport}
                    variant="contained"
                    sx={{ ...styleSheet.addCarrierButton, margin: "10px" }}
                    onClick={excelExportProductStockHistory}
                  >
                    {LanguageReducer?.languageType?.EXPORT_HISTORY_TEXT}
                  </LoadingButton>
                </Stack>
              </div>
            </Grid>

            {/* <Grid item container spacing={1} sm={12} sx={{ mt: "5px" }}>
                <Grid item xs={3}>
                  <InputLabel sx={styleSheet.inputLabel}>
                    {LanguageReducer?.languageType?.QTY_COMMITED_TEXT}
                  </InputLabel>
                  <Typography variant="p">{0}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel sx={styleSheet.inputLabel}>
                    {LanguageReducer?.languageType?.QTY_INCOMING_TEXT}
                  </InputLabel>
                  <Typography variant="p">{0}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel sx={styleSheet.inputLabel}>
                    {LanguageReducer?.languageType?.QTY_AVAILABLE_TEXT}
                  </InputLabel>
                  <Typography variant="p">{0}</Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel sx={styleSheet.inputLabel}>
                    {LanguageReducer?.languageType?.QTY_DAMAGE_TEXT}
                  </InputLabel>
                  <Typography variant="p">{0}</Typography>
                </Grid>
              </Grid> */}
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "white", height: "400px" }}
              sx={{ mt: "5px" }}
            >
              <DataGrid
                loading={isLoadingHistory}
                rowHeight={40}
                headerHeight={40}
                sx={{
                  fontFamily:
                    "'Lato Regular', 'Inter Regular', 'Arial' !important",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                getRowId={(row) => row.ProductStockHistorytId}
                rows={stockHistory}
                columns={columns}
                disableSelectionOnClick
                pageSize={5}
                rowsPerPageOptions={[5]}
                apiRef={gridApiRef}
                onGridReady={(params) => (gridApiRef.current = params.api)}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          sx={styleSheet.modalDismissButton}
          onClick={handleClose}
        >
          {LanguageReducer?.languageType?.DISMISS_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default StockHistoryModal;
