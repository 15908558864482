import {
  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  Table,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { GetAllProducts } from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import GeneralTabBar from "../../../components/shared/tabsBar";
import ProducList from "./productList";
import CustomReactDatePickerInputFilter from "../../../.reUseableComponents/TextField/CustomReactDatePickerInputFilter";
import UtilityClass from "../../../utilities/UtilityClass";
import useDateRangeHook from "../../../.reUseableComponents/CustomHooks/useDateRangeHook";
import { useSalePersonHook } from "../../../.reUseableComponents/CustomHooks/useSalePersonHook";
import { CicrlesLoading } from "../../../utilities/helpers/Helpers";
function Products(props) {
  const [products, setProducts] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isGridLoading, setIsGridLoading] = useState(false);
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    resetDates,
    startDateFormated,
    endDateFormated,
  } = useDateRangeHook();
  const {
    loading: loadingSaleConfig,
    saleChannelConfigIds,
    store,
    salePersonIds,
  } = useSalePersonHook();

  let getAllProducts = async () => {
    setIsGridLoading(true);
    let res = await GetAllProducts({
      filterModel: {
        createdFrom: startDateFormated ? startDateFormated : null,
        createdTo: endDateFormated ? endDateFormated : null,
        start: 0,
        length: 10000,
        search: "",
        sortDir: "desc",
        sortCol: 0,
      },
      storeId: store,
    });
    // console.log("data:::", res.data);
    setIsGridLoading(false);

    if (res.data.result !== null) setProducts(res.data.result.list);
  };
  const [isfilterClear, setIsfilterClear] = useState(false);

  const handleFilterRest = () => {
    setStartDate(null);
    setEndDate(null);
    setIsfilterClear(true);
  };

  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/products") {
      handleFilterRest();
    }
  }, [location.pathname]);
  useEffect(() => {
    if (isfilterClear) {
      if (store != "" && salePersonIds != "") {
        getAllProducts();
        resetDates();
        setIsfilterClear(false);
      }
    }
  }, [isfilterClear, store, salePersonIds]);
  return (
    <>
      {loadingSaleConfig ? (
        <CicrlesLoading />
      ) : (
        <Box sx={styleSheet.pageRoot}>
          <div style={{ padding: "10px" }}>
            {" "}
            <GeneralTabBar
              tabScreen={"products"}
              isFilterOpen={isFilterOpen}
              setIsFilterOpen={setIsFilterOpen}
              tabData={[
                {
                  label: LanguageReducer?.languageType?.ALL_TEXT,
                  route: "/products",
                },
                {
                  label: LanguageReducer?.languageType?.ACTIVE_TEXT,
                  route: "/products/active",
                },
                {
                  label: LanguageReducer?.languageType?.IN_ACTIVE_TEXT,
                  route: "/products/in-active",
                },
              ]}
              disableSearch
              {...props}
            />
            {isFilterOpen ? (
              <Table
                sx={{ ...styleSheet.generalFilterArea }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <Grid container spacing={2} sx={{ p: "6px" }}>
                      <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Grid>
                          <InputLabel
                            sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                          >
                            {LanguageReducer?.languageType?.START_DATE_TEXT}
                          </InputLabel>

                          <CustomReactDatePickerInputFilter
                            value={startDate}
                            maxDate={UtilityClass.todayDate()}
                            onClick={(date) => setStartDate(date)}
                            size="small"
                            isClearable

                            // inputProps={{ style: { padding: "2px 5px" } }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Grid>
                          <InputLabel
                            sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                          >
                            {LanguageReducer?.languageType?.END_DATE_TEXT}
                          </InputLabel>
                          <CustomReactDatePickerInputFilter
                            value={endDate}
                            onClick={(date) => setEndDate(date)}
                            size="small"
                            minDate={startDate}
                            maxDate={UtilityClass.todayDate()}
                            disabled={!startDate ? true : false}
                            isClearable
                            // inputProps={{ style: { padding: "2px 5px" } }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Stack
                          alignItems="flex-end"
                          direction="row"
                          spacing={1}
                          sx={{
                            ...styleSheet.filterButtonMargin,
                            display: "row",
                          }}
                        >
                          <Button
                            sx={{ ...styleSheet.filterIcon, minWidth: "100px" }}
                            color="inherit"
                            variant="outlined"
                            onClick={() => {
                              handleFilterRest();
                            }}
                          >
                            {LanguageReducer?.languageType?.CLEAR_FILTER_TEXT}
                          </Button>
                          <Button
                            sx={{ ...styleSheet.filterIcon, minWidth: "100px" }}
                            variant="contained"
                            onClick={() => {
                              getAllProducts();
                            }}
                          >
                            {"Filter"}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </TableRow>
                </TableHead>
              </Table>
            ) : null}
            <Routes>
              <Route
                path="/"
                element={
                  <ProducList
                    isGridLoading={isGridLoading}
                    products={products}
                    getAllProducts={getAllProducts}
                  />
                }
              />
              <Route
                path="/active"
                element={
                  <ProducList
                    isGridLoading={isGridLoading}
                    getAllProducts={getAllProducts}
                    products={products.filter((product) => {
                      return product.Active === true;
                    })}
                  />
                }
              />
              <Route
                path="/in-active"
                element={
                  <ProducList
                    getAllProducts={getAllProducts}
                    isGridLoading={isGridLoading}
                    products={products.filter((product) => {
                      return product.Active === false;
                    })}
                  />
                }
              />
            </Routes>
          </div>
          {/* </Container> */}
        </Box>
      )}
    </>
  );
}
export default Products;
