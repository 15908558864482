import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  InputLabel,
  Stack,
  Table,
  TableHead,
  TableRow,
} from "@mui/material";
import { saveAs } from "file-saver";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import * as XLSX from "xlsx";
import ButtonComponent from "../../../.reUseableComponents/Buttons/ButtonComponent";
import useDateRangeHook from "../../../.reUseableComponents/CustomHooks/useDateRangeHook";
import DataGridTabs from "../../../.reUseableComponents/DataGridTabs/DataGridTabs";
import SwitchMui from "../../../.reUseableComponents/Switch/SwitchMui";
import CustomAutocompleteFieldFilter from "../../../.reUseableComponents/TextField/CustomAutocompleteFieldFilter";
import CustomReactDatePickerInputFilter from "../../../.reUseableComponents/TextField/CustomReactDatePickerInputFilter";
import {
  ExcelExportProductInventory,
  ExcelExportProductInventorySummary,
  GetAllLowQuantityProductStock,
  GetAllProductInventory,
  GetAllStationLookup,
  GetStoresForSelection,
} from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import SyncInventoryModal from "../../../components/modals/productModals/SyncInventoryModal";
import UtilityClass from "../../../utilities/UtilityClass";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import InventoryList from "./inventoryList";
import { useSalePersonHook } from "../../../.reUseableComponents/CustomHooks/useSalePersonHook";
import { CicrlesLoading } from "../../../utilities/helpers/Helpers";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import { EnumOptions } from "../../../utilities/enum";

function Inventory(props) {
  const [inventory, setInventory] = useState([]);
  const [productStations, setProductStations] = useState([]);
  const { loading: loadingConfig, store, salePersonIds } = useSalePersonHook();

  const initialState = {
    store: {
      storeId: 0,
      storeName: "Select Please",
    },
    station: {
      productStationId: 0,
      sname: "Select Please",
    },
  };
  const [selectedStore, setSelectedStore] = useState(initialState.store);
  const [selectedStation, setSelectedStation] = useState(initialState.station);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [isGridLoading, setIsGridLoading] = useState(null);
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [isAvailable, setIsAvailable] = useState(false);
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    resetDates,
    startDateFormated,
    endDateFormated,
  } = useDateRangeHook();

  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const resetRowRef = useRef(false);
  const getOrdersRef = useRef([]);
  const [selectedItems, setSelectedItems] = useState([]);

  //#region
  const syncInventory = () => {
    setOpenSyncModal(true);
    //console.log("syncInventory");
  };

  //#endregion

  const exportToXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(inventory?.list);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    const buffer = XLSX.write(workbook, { type: "array", bookType: "xlsx" });
    const filename = "data.xlsx";
    saveAs(new Blob([buffer]), filename);
  };

  let getAllStationLookup = async () => {
    let res = await GetAllStationLookup();
    //console.log("getAllStationLookup", res.data);
    setProductStations(res.data.result);
  };

  const getFiltersFromState = () => {
    let filters = {
      filterModel: {
        createdFrom: startDateFormated ? startDateFormated : null,
        createdTo: endDateFormated ? endDateFormated : null,
        start: 0,
        length: 10000,
        search: "",
        sortDir: "desc",
        sortCol: 0,
      },
      ProductStationId: selectedStation?.productStationId,
      // productStockStatusId: 0,
      isAvailable: isAvailable,
      StoreId: store,
    };
    return filters;
  };
  const downloadExcel = () => {
    let params = getFiltersFromState();
    ExcelExportProductInventory(params)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          UtilityClass.downloadExcel(res.data, "inventory");
          // setIsLoading(false);
        } else {
          successNotification("data not found");
          // setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log("e", e);
        errorNotification("Unable to download");
      });
  };
  const downloadExcelSummary = () => {
    let params = getFiltersFromState();
    ExcelExportProductInventorySummary(params)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          UtilityClass.downloadExcel(res.data, "inventory summary");
          // setIsLoading(false);
        } else {
          successNotification("data not found");
          // setIsLoading(false);
        }
      })
      .catch((e) => {
        console.log("e", e);
        errorNotification("Unable to download");
      });
  };
  let getAllProductInventory = async () => {
    let filter = getFiltersFromState();

    setIsGridLoading(true);
    let res = await GetAllProductInventory(filter);
    setIsGridLoading(false);

    //console.log("getAllProductInventory:::", res.data);
    if (res.data.result != null) setInventory(res.data.result);
  };

  useEffect(() => {
    getAllStationLookup();
  }, []);
  // useEffect(() => {
  //   getAllProductInventory();
  // }, []);
  const handleFilterRest = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedStation(initialState.station);
  };
  const [isfilterClear, setIsfilterClear] = useState(false);
  const handleFilterClear = async () => {
    handleFilterRest();
    setIsfilterClear(true);
  };
  const location = useLocation();
  useEffect(() => {
    //console.log(location.pathname);
    if (location.pathname === "/inventory") {
      handleFilterClear();
    }
  }, [location.pathname]);
  useEffect(() => {
    if (isfilterClear) {
      if (store != "" && salePersonIds != "") {
        getAllProductInventory();
        resetDates();
        setIsfilterClear(false);
      }
    }
  }, [isfilterClear, store, salePersonIds]);

  useEffect(() => {
    if (store != "" && salePersonIds != "") {
      getAllProductInventory();
    }
  }, [isAvailable, store, salePersonIds]);

  const handleTabChange = async (event, filterValue) => {};
  return (
    <>
      {loadingConfig ? (
        <CicrlesLoading />
      ) : (
        <Box sx={styleSheet.pageRoot}>
          <div style={{ padding: "10px" }}>
            <Box sx={styleSheet.topNavBar}>
              <Box
                sx={{
                  ...styleSheet.topNavBarLeft,
                  fontWeight: "900 !important",
                }}
              ></Box>
              <Stack
                sx={styleSheet.topNavBarRight}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <ButtonGroup
                  variant="outlined"
                  aria-label="split button"
                ></ButtonGroup>
              </Stack>
            </Box>
            <DataGridTabs
              handleTabChange={handleTabChange}
              tabData={[
                {
                  label: LanguageReducer?.languageType?.ALL_TEXT,
                  route: "/inventory",
                },
                {
                  label: LanguageReducer?.languageType?.ACTIVE_TEXT,
                  route: "/inventory/active",
                },
                {
                  label: LanguageReducer?.languageType?.IN_ACTIVE_TEXT,
                  route: "/inventory/in-active",
                },
              ]}
              actionBtnMenuData={[
                // {
                //   title: "Stock Report PDF",
                //   onClick: () => {},
                // },
                {
                  title: "Stock Report Excel",
                  onClick: () => {
                    downloadExcel();
                  },
                },
                {
                  title: "Stock Summary Report",
                  onClick: () => {
                    downloadExcelSummary();
                  },
                },
              ]}
              otherBtns={
                <>
                  <SwitchMui
                    checked={isAvailable}
                    onChange={() => setIsAvailable((prev) => !prev)}
                    label="Available Only"
                  />
                </>
              }
              handleFilterBtnOnClick={() => {
                setIsFilterOpen(!isFilterOpen);
              }}
            />

            {isFilterOpen ? (
              <Table
                sx={{ ...styleSheet.generalFilterArea }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    <Grid container spacing={2} sx={{ p: "6px" }}>
                      <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Grid>
                          <InputLabel
                            sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                          >
                            {LanguageReducer?.languageType?.START_DATE_TEXT}
                          </InputLabel>

                          <CustomReactDatePickerInputFilter
                            maxDate={UtilityClass.todayDate()}
                            value={startDate}
                            onClick={(date) => setStartDate(date)}
                            size="small"
                            isClearable

                            // inputProps={{ style: { padding: "4px 5px" } }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Grid>
                          <InputLabel
                            sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                          >
                            {LanguageReducer?.languageType?.END_DATE_TEXT}
                          </InputLabel>
                          <CustomReactDatePickerInputFilter
                            maxDate={UtilityClass.todayDate()}
                            value={endDate}
                            onClick={(date) => setEndDate(date)}
                            size="small"
                            minDate={startDate}
                            disabled={!startDate ? true : false}
                            isClearable

                            // inputProps={{ style: { padding: "4px 5px" } }}
                          />
                        </Grid>
                      </Grid>

                      <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Grid>
                          <InputLabel
                            sx={{ ...styleSheet.inputLabel, overflow: "unset" }}
                          >
                            {"Station"}
                          </InputLabel>
                          <SelectComponent
                            name="reason"
                            height={28}
                            options={productStations}
                            value={selectedStation}
                            optionLabel={EnumOptions.SELECT_STATION.LABEL}
                            optionValue={EnumOptions.SELECT_STATION.VALUE}
                            getOptionLabel={(option) => option?.sname}
                            onChange={(e, val) => {
                              setSelectedStation(val);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
                        <Stack
                          direction={"row"}
                          sx={{
                            ...styleSheet.filterButtonMargin,
                            display: "row",
                          }}
                          spacing={1}
                        >
                          <Button
                            sx={{ ...styleSheet.filterIcon, minWidth: "100px" }}
                            color="inherit"
                            variant="outlined"
                            onClick={() => {
                              handleFilterClear();
                            }}
                          >
                            {LanguageReducer?.languageType?.CLEAR_FILTER_TEXT}
                          </Button>
                          <Button
                            sx={{ ...styleSheet.filterIcon, minWidth: "100px" }}
                            variant="contained"
                            onClick={() => {
                              getAllProductInventory();
                            }}
                          >
                            {"Filter"}
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </TableRow>
                </TableHead>
              </Table>
            ) : null}
            <Routes>
              <Route
                path="/"
                element={
                  <InventoryList
                    inventory={inventory?.list ? inventory?.list : []}
                    getAllProductInventory={getAllProductInventory}
                    isGridLoading={isGridLoading}
                    getOrdersRef={getOrdersRef}
                    resetRowRef={resetRowRef}
                    setSelectedItems={setSelectedItems}
                  />
                }
              />
              <Route
                path="/active"
                element={
                  <InventoryList
                    getAllProductInventory={getAllProductInventory}
                    inventory={
                      inventory?.list
                        ? inventory?.list.filter((product) => {
                            return product.Active === true;
                          })
                        : []
                    }
                    getOrdersRef={getOrdersRef}
                    resetRowRef={resetRowRef}
                    setSelectedItems={setSelectedItems}
                    isGridLoading={isGridLoading}
                  />
                }
              />
              <Route
                path="/in-active"
                element={
                  <InventoryList
                    getAllProductInventory={getAllProductInventory}
                    inventory={
                      inventory?.list
                        ? inventory?.list.filter((product) => {
                            return product.Active !== true;
                          })
                        : []
                    }
                    getOrdersRef={getOrdersRef}
                    resetRowRef={resetRowRef}
                    setSelectedItems={setSelectedItems}
                    isGridLoading={isGridLoading}
                  />
                }
              />
            </Routes>
          </div>
          {openSyncModal && (
            <SyncInventoryModal
              open={openSyncModal}
              setOpen={setOpenSyncModal}
              getAll={getAllProductInventory}
              selectedItems={getOrdersRef.current}
            />
          )}
        </Box>
      )}
    </>
  );
}
export default Inventory;
