import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";

export default function SearchInputAutoCompleteMultiple(props) {
  const {
    onChange = () => {},
    handleFocus = () => {},
    inputFields = [],
    MAX_TAGS = 150,
  } = props;
  return (
    <Autocomplete
      sx={{ padding: "5px 7px" }}
      multiple
      // sx={{ marginBottom: "5px" }}
      id="tags-filled"
      variant="outlined"
      size="small"
      onFocus={handleFocus}
      onChange={onChange}
      value={inputFields}
      options={[]}
      freeSolo
      renderTags={(value, getTagProps) => {
        return value
          .slice(0, MAX_TAGS)
          .map((option, index) => (
            <Chip
              key={index}
              variant="outlined"
              size="small"
              label={option}
              {...getTagProps({ index })}
            />
          ));
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            "& fieldset": { border: "none" },
            borderRadius: "5px",
            background: "#fff",
            boxShadow: 1,
            // border: "1px solid rgba(224, 224, 224, 1)",
          }}
          variant="outlined"
          size="small"
          placeholder="Search"
        />
      )}
    />
  );
}
