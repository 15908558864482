import { setThisKeyCookie } from "../../../utilities/cookies";

const setLoginCookies = (res) => {
  setThisKeyCookie("access_token", res.data.result.access_token);
  setThisKeyCookie("refresh_token", res.data.result.refresh_token);
  setThisKeyCookie("user_name", res.data.result.user_name);
  setThisKeyCookie("clIdentifier", res.data.result.clientPrefix);
  setThisKeyCookie("id_token", res.data.result.id_token);
  setThisKeyCookie("patronTypeId", res?.data?.result?.userRoleId);
  setThisKeyCookie(
    "expires_in",
    res.data.result.expires_in * 1000 + Date.now()
  );
  setThisKeyCookie("companyImage", res.data.result.companyImage);
  setThisKeyCookie("email", res.data.result.email);
};

export default setLoginCookies;
