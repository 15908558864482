import React, { useEffect, useState } from "react";
import {
  Card,
  Dialog,
  DialogContent,
  Box,
  Chip,
  DialogActions,
  Button,
  DialogTitle,
  Typography,
  Paper,
  InputLabel,
  TextField,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { styleSheet } from "../../../assets/styles/style";
import Slide from "@mui/material/Slide";
import { useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import UtilityClass from "../../../utilities/UtilityClass";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import {
  AssignToCarrier,
  BatchOutScanDeliveryTask,
  CreateShipmentGridColumn,
  GetAllCarrierPaymentSettlements,
  GetAllCarrierTrackingStatusForSelection,
  MoveDashboardStatusFromOneTabToAnother,
} from "../../../api/AxiosInterceptors";
import { LoadingButton } from "@mui/lab";
import CustomReactDatePickerInput from "../../../.reUseableComponents/TextField/CustomReactDatePickerInput";
import SearchInputAutoCompleteMultiple from "../../../.reUseableComponents/TextField/SearchInputAutoCompleteMultiple";
import CustomAutocompleteFieldFilter from "../../../.reUseableComponents/TextField/CustomAutocompleteFieldFilter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function AddShipmentTabModal(props) {
  let { open, setOpen, setStatusMoved } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const [allCarrierTrackingStatus, setAllCarrierTrackingStatus] =
    React.useState([]);
  const [tabName, setTabName] = React.useState("");
  const [selectedStatusIds, setSelectedStatusIds] = useState([]);

  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const MAX_TAGS = 1000;
  let getAllCarrierTrackingStatusForSelection = async () => {
    let res = await GetAllCarrierTrackingStatusForSelection();

    if (res.data.result !== null) {
      if (res.data.result.find((x) => x?.carrierTrackingStatusId == 0)) {
        res.data.result = res.data.result?.filter(
          (x) => x?.carrierTrackingStatusId != 0
        );
      }
      console.log("data", res.data.result);

      setAllCarrierTrackingStatus(res.data.result);
    }
  };
  useEffect(() => {
    getAllCarrierTrackingStatusForSelection();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    if (tabName == "") {
      errorNotification("Please Enter tab name");
      return false;
    }
    if (selectedStatusIds.length == 0) {
      errorNotification("Please select statuses for this tab");
      return false;
    }
    var dashboardStatusIdValues = selectedStatusIds
      .map((status) => status.carrierTrackingStatusId)
      .join(",");
    let params = {
      columnName: tabName?.trim().toUpperCase(),
      dashboardStatusIdValues: dashboardStatusIdValues,
    };
    setIsLoading(true);
    CreateShipmentGridColumn(params)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          UtilityClass.showErrorNotificationWithDictionary(res.data?.errors);
          // setIsLoading(false);
        } else {
          successNotification("Tab Created successfully");
          setStatusMoved(true);
          handleClose();
        }
      })
      .catch((e) => {
        console.log("e", e);
        errorNotification("Unable to download");
      })
      .finally((e) => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      onClose={handleClose}
      maxWidth="md"
      sx={styleSheet.modelMainClassWithImage}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          overflowY: "visible",
        },
      }}
    >
      <DialogTitle sx={styleSheet.outScanHeading}>Add Tab</DialogTitle>
      <DialogContent sx={{ ...styleSheet.modelContentArea }}>
        <Box mb={1}>
          <InputLabel sx={styleSheet.inputLabel}>Tab Name</InputLabel>
          <TextField
            onChange={(e) => setTabName(e.target.value)}
            size="small"
            fullWidth
            variant="outlined"
            value={tabName}
          />
        </Box>
        <Box>
          <InputLabel sx={styleSheet.inputLabel}>Select Status</InputLabel>
          <CustomAutocompleteFieldFilter
            multiple={true}
            name="reason"
            options={allCarrierTrackingStatus}
            value={selectedStatusIds}
            getOptionLabel={(option) => option?.trackingStatus}
            onChange={(e, val) => {
              setSelectedStatusIds(val);
            }}
            padding={"5px "}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          sx={{
            ...styleSheet.modalDismissButton,
            borderBottomLeftRadius: "20px  !important",
          }}
          onClick={handleClose}
        >
          {LanguageReducer?.languageType?.DISMISS_TEXT}
        </Button>
        <LoadingButton
          fullWidth
          variant="contained"
          sx={{
            ...styleSheet.modalCarrierSubmitButton,
            borderBottomRightRadius: "20px !important",
          }}
          onClick={(e) => handleSubmit()}
          loading={isLoading}
        >
          {"Add Tab"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
export default AddShipmentTabModal;
