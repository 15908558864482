import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import {
  Autocomplete,
  GoogleMap,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../../assets/styles/googleMapStyle.css";
import { styleSheet } from "../../../assets/styles/style";
import {
  BackdropCustom,
  placeholders,
} from "../../../utilities/helpers/Helpers";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GoogleMapWithSearch(props) {
  const {
    open,
    setOpen,
    isEditMode = false,
    handleMapValues = () => {},
  } = props;

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ["places"],
  });
  const [searchResult, setSearchResult] = useState("Result: none");
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [center, setCenter] = useState({ lat: 48.8584, lng: 2.2945 });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [autocompleteInput, setAutocompleteInput] = useState("");
  const [autocomplete, setAutocomplete] = useState(null);

  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const handleClose = () => {
    setAutocompleteInput("");
    setOpen(false);
  };
  const handleSubmit = () => {
    let lat = (markerPosition?.lat ? markerPosition?.lat?.toFixed(6) : 0) || 0;
    let lng = (markerPosition?.lng ? markerPosition?.lng?.toFixed(6) : 0) || 0;

    let fixedLatLng = {
      lat: lat,
      lng: lng,
    };
    handleMapValues(fixedLatLng);
    setOpen(false);
  };
  const [autocompleteBounds, setAutocompleteBounds] = useState(null);

  function onLoad(autocomplete) {
    // set bounds for Autocomplete
    setAutocompleteBounds(
      new window.google.maps.LatLngBounds(
        new window.google.maps.LatLng(24.396308, 54.296043), // Southwest corner of Dubai
        new window.google.maps.LatLng(28.611799, 71.5388) // Northeast corner of Saudi Arabia
      )
    );

    setSearchResult(autocomplete);
  }
  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();

      if (!place.geometry || !place.geometry.location) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      const formattedAddress = place.formatted_address;
      const { lat, lng } = place.geometry.location;

      setMarkerPosition({ lat: lat(), lng: lng() });
      map.panTo({ lat: lat(), lng: lng() });
    } else {
      alert("Please enter text");
    }
  }
  const getAddressFromLatLng = () => {
    if (map) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: markerPosition }, (results, status) => {
        if (status === "OK" && results[0]) {
          setAutocompleteInput(results[0].formatted_address);
        } else {
          // setSearchResult('Address not found');
        }
      });
    }
  };
  const handleMarkerDragEnd = (e) => {
    const { latLng } = e;
    const lat = latLng.lat();
    const lng = latLng.lng();
    setMarkerPosition({ lat, lng });
  };
  useEffect(() => {
    if (markerPosition) {
      getAddressFromLatLng();
      if (!isEditMode) {
        let lat =
          (markerPosition?.lat ? markerPosition?.lat?.toFixed(6) : 0) || 0;
        let lng =
          (markerPosition?.lng ? markerPosition?.lng?.toFixed(6) : 0) || 0;
        props?.setValue("latitude", lat);
        props?.setValue("longitude", lng);
      }
    }
  }, [markerPosition]);
  useEffect(() => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          const coords = pos.coords;
          setCenter({
            lat: coords.latitude,
            lng: coords.longitude,
          });
          setMarkerPosition({
            lat: coords.latitude,
            lng: coords.longitude,
          });
        },
        (error) => {
          console.log("Error Code = " + error.code + " - " + error.message);
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.error("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.error("The request to get user location timed out.");
              break;
            case error.UNKNOWN_ERROR:
              console.error("An unknown error occurred.");
              break;
          }
          //   warningNotification(
          //     "Please enable you gps location to get better result"
          //   );
          setMarkerPosition({
            lat: 25.2048,
            lng: 55.2708,
          });
          getAddressFromLatLng();
        }
      );
    }
  }, []);

  const handleFocus = (event) => event.target.select();
  return (
    <Dialog
      fullWidth
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="lg"
      sx={styleSheet.modelMainClassWithImage}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        sx: {
          maxHeight: "100vh",
        },
      }}
    >
      <DialogTitle sx={styleSheet.allProductionHeading}>
        {LanguageReducer?.languageType?.CURRENT_TEXT}{" "}
        {LanguageReducer?.languageType?.LOCATION_TEXT}
      </DialogTitle>
      <DialogContent
        style={{ height: "70vh" }}
        sx={{ ...styleSheet.modelContentArea }}
      >
        <DialogContentText id="alert-dialog-slide-description">
          {isLoaded ? (
            <>
              <Autocomplete
                id="autocomplete"
                onPlaceChanged={onPlaceChanged}
                onLoad={onLoad}
                bounds={autocompleteBounds}
                restrictions={{
                  country: ["ae", "sa"],
                }}
                options={{
                  types: ["geocode"],
                  strictBounds: true,
                }}
              >
                <TextField
                  placeholder={placeholders.searchMap}
                  type="text"
                  size="small"
                  id="autocomplete"
                  name="autocomplete"
                  fullWidth
                  variant="outlined"
                  value={autocompleteInput}
                  onChange={(e) => setAutocompleteInput(e.target.value)}
                />
              </Autocomplete>
              <br />
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "60vh" }}
                center={{
                  lat: markerPosition ? markerPosition.lat : 0,
                  lng: markerPosition ? markerPosition.lng : 0,
                }} // Initial center coordinates
                zoom={15} // Initial zoom level
                onClick={(e) => {
                  setMarkerPosition({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  });
                }}
                onLoad={(map) => setMap(map)}
              >
                {markerPosition && (
                  <MarkerF
                    position={{
                      lat: markerPosition.lat,
                      lng: markerPosition.lng,
                    }}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                  />
                )}
              </GoogleMap>
            </>
          ) : (
            <BackdropCustom open={isLoaded} />
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          sx={styleSheet.modalDismissButton}
          onClick={() => {
            handleClose();
          }}
        >
          {"Dismiss"}
        </Button>
        <Button
          fullWidth
          variant="contained"
          sx={styleSheet.modalCarrierSubmitButton}
          onClick={(e) => handleSubmit(e)}
        >
          {"Add Location"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default GoogleMapWithSearch;
