import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Link, useNavigate } from "react-router-dom";
import {
  CheckUsernameAvailability,
  GetAllCountry,
  GetAllRegionbyCountryId,
  GetCityByRegionId,
  Signup,
  UploadClientImage,
} from "../../../api/AxiosInterceptors";
import avatarIcon from "../../../assets/images/avatar.png";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import { styleSheet } from "../../../assets/styles/style";
import { Controller, useForm, useWatch } from "react-hook-form";
import logout from "../../../assets/images/AlisImages/logout/logout.svg";
import UtilityClass from "../../../utilities/UtilityClass";
import { setUserCredential } from "../../../utilities/cookies/setCookie";
import CustomPhoneInput from "../../../.reUseableComponents/TextField/CustomPhoneInput";
import PhoneInput from "react-phone-input-2";
import "../../../assets/styles/phoneInput.css";
import CustomRHFPhoneInput from "../../../.reUseableComponents/TextField/CustomRHFPhoneInput";
import RHFCustomAutocompleteField from "../../../.reUseableComponents/TextField/RHFCustomAutocompleteField";
import { placeholders, purple } from "../../../utilities/helpers/Helpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SignUpPage() {
  let navigate = useNavigate();
  const [values, setValues] = useState({});
  const [allCountries, setAllCountries] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [file, setFile] = useState();
  const [imageURL, setImageURL] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    control,
  } = useForm();
  console.log("errors", errors);
  useWatch({
    name: "country",
    control,
  });
  useWatch({
    name: "region",
    control,
  });
  useWatch({
    name: "city",
    control,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const signUpForm = async (data) => {
    console.log("data", data);
    setIsLoading(true);
    console.log("values", values);
    let checkUsernameAvailability = await CheckUsernameAvailability({
      userName: data.userName,
    });

    if (!checkUsernameAvailability?.data?.isSuccess) {
      errorNotification(
        LanguageReducer?.languageType?.USERNAME_NOT_AVAILABLE_TOAST
      );
      errorNotification(
        checkUsernameAvailability?.data?.errors?.UsernameExists[0]
      );
      setIsLoading(false);
      return;
    }

    const body = {
      clientName: data.clientName,
      clientImage: imageURL,
      countryId: data.country.countryId,
      regionId: data.region.regionId,
      cityId: data.city.cityId,
      clientCompanyName: data.companyName,
      email: data.email,
      userName: data.userName,
      mobile: UtilityClass.getFormatedNumber(data.mobile),
      streetAddress:
        data.streetAddress +
        ", " +
        data.city.name +
        ", " +
        data.region.name +
        ", " +
        data.country.name,
      password: data.password,
      phone: UtilityClass.getFormatedNumber(data.phone),
    };
    console.log("body", body);

    Signup(body)
      .then((res) => {
        setIsLoading(false);

        console.log("res:", res);
        if (!res?.data?.isSuccess) {
          UtilityClass.showErrorNotificationWithDictionary(res?.data?.errors);
          // errorNotification(
          //   LanguageReducer?.languageType?.UNABLE_TO_CREATE_USER_TOAST
          // );
          // errorNotification(res?.data?.customErrorMessage);
        } else {
          const credData = {
            userName: body.userName,
            password: body.password,
          };
          let uData = UtilityClass.encryptData(credData);
          setUserCredential(uData);

          navigate("/verify-otp", { state: { userName: data.userName } });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log("e", e);
        errorNotification(
          LanguageReducer?.languageType?.UNABLE_TO_CREATE_USER_TOAST
        );
      });
  };

  let uploadClientImage = (e) => {
    console.log("e", e.target.files[0]);
    setFile(e.target.files[0]);
    const formData = new FormData();
    formData.append("file", e.target.files[0]);
    UploadClientImage(formData)
      .then((res) => {
        console.log("res", res);
        setImageURL(res.data.result.url);
        //successNotification(res.data.result.message);
      })
      .catch((e) => console.log("e", e));
  };

  let getAllCountry = async () => {
    let res = await GetAllCountry({});
    console.log("res", res.data.result);
    if (res.data.result != null) {
      setAllCountries(res.data.result);
    }
  };
  let getAllRegionbyCountryId = async () => {
    setValue("region", null);
    let res = await GetAllRegionbyCountryId(getValues("country").countryId);
    console.log("data", res.data.result);
    if (res.data.result != null) setAllRegions(res.data.result);
  };
  let getCityByRegionId = async () => {
    let res = await GetCityByRegionId(getValues("region").regionId);
    console.log("data", res.data.result);
    if (res.data.result != null) setAllCities(res.data.result);
  };
  useEffect(() => {
    getAllCountry();
  }, []);
  useEffect(() => {
    setValue("region", null);
    setValue("city", null);
    setAllRegions([]);
    setAllCities([]);
    if (getValues("country")) getAllRegionbyCountryId();
  }, [getValues("country")]);
  useEffect(() => {
    setValue("city", null);
    setAllCities([]);
    if (getValues("region")) getCityByRegionId();
  }, [getValues("region")]);

  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const handleFocus = (event) => event.target.select();
  return (
    <Box
      display={"flex"}
      sx={{
        "& .MuiInputBase-root": {
          borderRadius: "10px",
        },
        margin: "0px 15px",
        height: "calc(100vh - 64px);",
      }}
    >
      <Box flexBasis={"67%"} className="flex_center" px={7}>
        <form onSubmit={handleSubmit(signUpForm)}>
          <Box sx={styleSheet.addDriverHeadingAndUpload}>
            <Typography sx={styleSheet.addDriverHeading}>
              {LanguageReducer?.languageType?.SIGN_UP_TEXT}
            </Typography>
            <Box sx={styleSheet.uploadIconArea}>
              <IconButton component="label" sx={{ position: "relative" }}>
                <Avatar
                  sx={{
                    width: 90,
                    height: 90,
                    border: file && "1px solid grey",
                  }}
                  src={file ? file && URL.createObjectURL(file) : avatarIcon}
                ></Avatar>
                <Box
                  width={24}
                  height={24}
                  bgcolor={purple}
                  borderRadius={"50%"}
                  position={"absolute"}
                  bottom={8}
                  right={16}
                  className={"flex_center"}
                >
                  <BorderColorIcon
                    sx={{
                      width: 15,
                      height: 15,
                      color: "#fff",
                    }}
                  />
                </Box>
                <input
                  onChange={uploadClientImage}
                  hidden
                  accept="image/*"
                  type="file"
                />
              </IconButton>
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item md={6} sm={6} xs={12}>
              <InputLabel required sx={styleSheet.inputLabel}>
                {"Name"}
              </InputLabel>
              <TextField
                placeholder={placeholders.name}
                onFocus={handleFocus}
                type="text"
                size="small"
                id="clientName"
                name="clientName"
                fullWidth
                variant="outlined"
                {...register("clientName", {
                  required: {
                    value: true,
                    message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                  },
                  pattern: {
                    value: /^(?!\s*$).+/,
                    message:
                      LanguageReducer?.languageType
                        ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                  },
                })}
                error={Boolean(errors.clientName)} // set error prop
                helperText={errors.clientName?.message}
              />
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <InputLabel sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.COMPANY_NAME_TEXT}
                <span sx={styleSheet.inputLabelSmallText}>
                  ({LanguageReducer?.languageType?.OPTIONAL_TEXT})
                </span>
              </InputLabel>
              <TextField
                placeholder={placeholders.company_name}
                onFocus={handleFocus}
                type="text"
                size="small"
                id="companyName"
                name="companyName"
                fullWidth
                variant="outlined"
                {...register("companyName")}
                error={Boolean(errors.companyName)} // set error prop
                helperText={errors.companyName?.message}
              />
            </Grid>

            <Grid item md={4} sm={6} xs={12}>
              <InputLabel required sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.EMAIL_TEXT}
              </InputLabel>
              <TextField
                placeholder={placeholders.email}
                onFocus={handleFocus}
                type="email"
                size="small"
                id="email"
                name="email"
                fullWidth
                variant="outlined"
                {...register("email", {
                  required: {
                    value: true,
                    message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                  },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, // set pattern to match email format
                    message: LanguageReducer?.languageType?.INVALID_EMAIL_TOAST,
                  },
                })}
                error={Boolean(errors.email)} // set error prop
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel required sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.MOBILE_NUMBER_TEXT}
              </InputLabel>
              <CustomRHFPhoneInput
                error={LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT}
                name="mobile"
                control={control}
                required
              />
            </Grid>
            {/* <Grid item md={6} sm={6} xs={12}>
              <InputLabel required sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.MOBILE_NUMBER_TEXT}
              </InputLabel>
              <TextField
                placeholder="+XX XXX XXXX XXX"
                onFocus={handleFocus}
                type="text"
                size="small"
                id="mobile"
                name="mobile"
                fullWidth
                variant="outlined"
                {...register("mobile", {
                  required: {
                    value: true,
                    message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                  },
                  pattern: {
                    value: /^(?:\+)?\d{1,3}?\d{9,15}$/,
                    message:
                      LanguageReducer?.languageType
                        ?.PLEASE_ENTER_A_VALID_MOBILE_NUMBER,
                  },
                })}
                error={Boolean(errors.mobile)} // set error prop
                helperText={errors.mobile?.message}
              />
            </Grid> */}
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.PHONE_NUMBER_TEXT}
                <span sx={styleSheet.inputLabelSmallText}>
                  ({LanguageReducer?.languageType?.OPTIONAL_TEXT})
                </span>
              </InputLabel>
              <CustomRHFPhoneInput name="phone" control={control} />
              {/* <TextField
                placeholder="+XX XXX XXXX XXX"
                onFocus={handleFocus}
                type="text"
                size="small"
                id="phone"
                name="phone"
                fullWidth
                variant="outlined"
                {...register("phone")}
                error={Boolean(errors.phone)} // set error prop
                helperText={errors.phone?.message}
              /> */}
            </Grid>
            <Grid item md={4} sm={12}>
              <InputLabel required sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.SELECT_COUNTRY_TEXT}
              </InputLabel>
              <RHFCustomAutocompleteField
                name="country"
                control={control}
                options={allCountries}
                getOptionLabel={(option) => option?.name}
                requiredError={
                  LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT
                }
                value={getValues("country")}
                errors={errors}
                onChange={(event, newValue) => {
                  const resolvedId = newValue ? newValue : null;
                  setValue("country", resolvedId);
                }}
                rules={{
                  required: {
                    value: true,
                    message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                  },
                  validate: {
                    positive: (v) => parseInt(v?.countryId) > 0,
                  },
                }}
                required
              />
            </Grid>
            <Grid item md={4} sm={12}>
              <InputLabel required sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.SELECT_REGION_TEXT}
              </InputLabel>
              <RHFCustomAutocompleteField
                name="region"
                control={control}
                options={allRegions}
                getOptionLabel={(option) => option?.name}
                requiredError={
                  LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT
                }
                value={getValues("region")}
                errors={errors}
                disabled={!getValues("country")}
                onChange={(event, newValue) => {
                  const resolvedId = newValue ? newValue : null;
                  setValue("region", resolvedId);
                }}
                rules={{
                  required: {
                    value: true,
                    message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                  },
                  validate: {
                    positive: (v) => parseInt(v?.regionId) > 0,
                  },
                }}
                required
              />
            </Grid>
            <Grid item md={4} sm={12}>
              <InputLabel required sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.SELECT_CITY_TEXT}
              </InputLabel>
              <RHFCustomAutocompleteField
                name="city"
                control={control}
                options={allCities}
                getOptionLabel={(option) => option?.name}
                requiredError={
                  LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT
                }
                value={getValues("city")}
                errors={errors}
                onChange={(event, newValue) => {
                  const resolvedId = newValue ? newValue : null;
                  setValue("city", resolvedId);
                }}
                disabled={!getValues("region")}
                rules={{
                  required: {
                    value: true,
                    message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                  },
                  validate: {
                    positive: (v) => parseInt(v?.cityId) > 0,
                  },
                }}
                required
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <InputLabel required sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.APPARTMENT_SUITS_ETC_TEXT}
              </InputLabel>
              <TextField
                placeholder={placeholders.apartment}
                onFocus={handleFocus}
                type="text"
                size="small"
                id="streetAddress"
                name="streetAddress"
                fullWidth
                variant="outlined"
                {...register("streetAddress", {
                  required: {
                    value: true,
                    message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                  },
                })}
                error={Boolean(errors.streetAddress)} // set error prop
                helperText={errors.streetAddress?.message}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <InputLabel required sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.USER_NAME_TEXT}
              </InputLabel>
              <TextField
                placeholder={placeholders.name}
                onFocus={handleFocus}
                type="text"
                size="small"
                id="userName"
                name="userName"
                fullWidth
                variant="outlined"
                {...register("userName", {
                  required: {
                    value: true,
                    message: LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                  },
                  pattern: {
                    value: /^(?! +$)[a-z0-9]+$/,
                    message:
                      LanguageReducer?.languageType
                        ?.INPUT_SHOULD_NOT_CONTAIN_SPACES_OR_UPPERCASE,
                  },
                })}
                error={Boolean(errors.userName)} // set error prop
                helperText={errors.userName?.message}
              />
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <InputLabel sx={styleSheet.inputLabel} required>
                {LanguageReducer?.languageType?.PASSWORD_TEXT}
              </InputLabel>
              <FormControl
                fullWidth
                variant="outlined"
                error={Boolean(errors.password)}
              >
                <OutlinedInput
                  placeholder={"●●●●●●●●●"}
                  onFocus={handleFocus}
                  type={values.showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  size="small"
                  fullWidth
                  id="password"
                  name="password"
                  {...register("password", {
                    required: {
                      value: true,
                      message:
                        LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                    },
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+[\]{}|;':",./<>?]).{8,}$/,
                      message:
                        LanguageReducer?.languageType
                          ?.PASSWORD_MUST_CONTAIN_MINIMUM_8_CHARACTERS_1_UPPERCASE_1_LOWERCASE_1_NUMBER_1_SPECIAL_CHARACTER,
                    },
                  })}
                />
                <FormHelperText>{errors.password?.message}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item md={4} sm={12} xs={12}>
              <InputLabel sx={styleSheet.inputLabel} required>
                {LanguageReducer?.languageType?.CONFIRM_PASSWORD_TEXT}
              </InputLabel>
              <FormControl
                fullWidth
                variant="outlined"
                error={Boolean(errors.confirmPassword)}
              >
                <OutlinedInput
                  placeholder={"●●●●●●●●●"}
                  onFocus={handleFocus}
                  type={values.showConfirmPassword ? "text" : "password"}
                  value={values.confirmPassword}
                  onChange={(e) =>
                    setValues({ ...values, confirmPassword: e.target.value })
                  }
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {values.showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  size="small"
                  fullWidth
                  id="confirmPassword"
                  name="confirmPassword"
                  {...register("confirmPassword", {
                    required: {
                      value: true,
                      message:
                        LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                    },
                    pattern: {
                      value:
                        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-=_+[\]{}|;':",./<>?]).{8,}$/,
                      message:
                        LanguageReducer?.languageType
                          ?.PASSWORD_MUST_CONTAIN_MINIMUM_8_CHARACTERS_1_UPPERCASE_1_LOWERCASE_1_NUMBER_1_SPECIAL_CHARACTER,
                    },
                  })}
                />
                <FormHelperText>
                  {errors.confirmPassword?.message}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <br />

          <Typography sx={styleSheet.integrationCardDes}>
            {LanguageReducer?.languageType?.ALREADY_HAVE_AN_ACCOUNT_TEXT}{" "}
            <Link to="/">{LanguageReducer?.languageType?.LOGIN_TEXT}</Link>
          </Typography>

          {isLoading ? (
            <Button
              fullWidth
              variant="contained"
              sx={styleSheet.modalSubmitButton}
              disabled
            >
              <CircularProgress sx={{ color: "white" }} />
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              sx={{
                ...styleSheet.modalSubmitButton,
                borderRadius: "20px",
                mt: 1,
              }}
              type="submit"
            >
              {LanguageReducer?.languageType?.CREATE_ACCOUNT_TEXT}
            </Button>
          )}
        </form>
      </Box>
      <Box flexBasis={"33%"}>
        <Box
          component={"img"}
          src={logout}
          width={"100%"}
          height={"100%"}
          bgcolor={"primary.main"}
        />
      </Box>
    </Box>
  );
}
export default SignUpPage;
