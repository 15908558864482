import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useSelector } from "react-redux";
import OrderDetailModal from "../../../.reUseableComponents/Modal/InfoModal";
import {
  GetOrderItemByOrderNo,
  GetShipmentInfoByOrderNo,
  RefreshCarrierStatus,
} from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import OrderItemDetailModal from "../../../components/modals/orderModals/OrderItemDetailModal";
// import OrderItemDetailModal from "../../../.reUseableComponents/Modal/InfoModal";
import StatusBadge from "../../../components/shared/statudBadge";
import UtilityClass from "../../../utilities/UtilityClass";
import {
  EnumChangeFilterModelApiUrls,
  EnumDetailsModalType,
  EnumFullFillmentStatus,
} from "../../../utilities/enum";
import Colors from "../../../utilities/helpers/Colors";
import {
  CodeBox,
  DescriptionBoxWithChild,
  DialerBox,
  amountFormat,
  checkRefArrayValue,
  fetchMethod,
  rightColumn,
  useGetWindowHeight,
  usePagination,
} from "../../../utilities/helpers/Helpers";
import DataGridProComponent from "../../../.reUseableComponents/DataGrid/DataGridProComponent.js";

function OrderList(props, ref) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    allOrders,
    setAllOrders,
    getOrdersRef,
    resetRowRef,
    loading,
    allCarrierTrackingStatus,
    getAllCarrierTrackingStatusForSelection,
    setShowActionToolbarButtons,
    getAllOrders,
    allOrdersCount,
  } = props;
  const { height: windowHeight } = useGetWindowHeight();
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [orderIteminfoModal, setOrderIteminfoModal] = useState({
    open: false,
    loading: {},
    data: [],
  });
  const [infoModal, setInfoModal] = useState({
    open: false,
    loading: {},
    data: [],
  });
  const [orderNo, setOrderNo] = useState("");
  // const [isNoteAdded, setIsNoteAdded] = useState(false)
  const [isNoteAdded, setIsNoteAdded] = useState({
    loading: false,
    orderNo: "",
    isAdded: false,
  });

  const [refreshCarrierStatusLoading, setRefreshCarrierStatusLoading] =
    useState({});

  const handleGetShipmentInfoByOrderNo = async (orderNum) => {
    // let orderNum = orderNo; //if use action button from dropdown
    setInfoModal((prev) => ({ ...prev, loading: { [orderNum]: true } }));
    setIsNoteAdded((prev) => ({ ...prev, loading: { [orderNum]: true } }));
    await GetShipmentInfoByOrderNo(orderNum)
      .then((res) => {
        const response = res.data;
        setInfoModal((prev) => ({ ...prev, open: true, data: response }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setInfoModal((prev) => ({ ...prev, loading: { [orderNum]: false } }));
        setIsNoteAdded((prev) => ({ ...prev, loading: { [orderNum]: false } }));
      });
  };
  useEffect(() => {
    if (isNoteAdded.orderNo && isNoteAdded.isAdded) {
      handleGetShipmentInfoByOrderNo(isNoteAdded.orderNo);
      setIsNoteAdded((prev) => ({ ...prev, isAdded: false }));
    }
  }, [isNoteAdded.isAdded]);
  const handleActionButton = (cTarget, orderData) => {
    setAnchorEl(cTarget);
    setOrderNo(orderData.OrderNo);
  };
  const handleGetShipmentItemInfoByOrderNo = async (orderNum) => {
    setOrderIteminfoModal((prev) => ({
      ...prev,
      loading: { [orderNum]: true },
    }));

    await GetOrderItemByOrderNo(orderNum)
      .then((res) => {
        const response = res.data;
        setOrderIteminfoModal((prev) => ({
          ...prev,
          open: true,
          data: response,
        }));
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setOrderIteminfoModal((prev) => ({
          ...prev,
          loading: { [orderNum]: false },
        }));
      });
  };

  const getCarrierText = (orderNo) => {
    let str = "Unassigned";
    // Check if any object has carrier
    const order = allOrders.find((x) => x.OrderNo == orderNo);
    if (order?.CarrierId) {
      str = order?.CarrierName || "";
    }
    return str;
  };

  const handleRefresh = (rowArray) => {
    console.log("first");
    (rowArray || getOrdersRef?.current).forEach(async (ordNo) => {
      const selectedOrder = allOrders.find((ord) => ord.OrderNo === ordNo);
      const hasRefreshBtn =
        !selectedOrder?.IsClientCarrier && selectedOrder?.CarrierId != null;
      if (hasRefreshBtn) {
        const { response } = await fetchMethod(
          () => RefreshCarrierStatus(selectedOrder.OrderId),
          setRefreshCarrierStatusLoading,
          true,
          selectedOrder.OrderId
        );
        if (response && response.result) {
          const updatedStatus = response.result.data;
          setAllOrders((prev) => {
            const _orders = [...prev];
            const _selectedOrderIndex = _orders.findIndex(
              (ord) => ord.OrderId == selectedOrder.OrderId
            );
            _orders[_selectedOrderIndex].TrackingStatus =
              updatedStatus.trackingStatus;
            _orders[_selectedOrderIndex].CarrierLastUpdateDateTime =
              updatedStatus.carrierLastUpdateDateTime;
            return _orders;
          });
          const isStatusExist = allCarrierTrackingStatus.some(
            (dt) =>
              dt.carrierTrackingStatusId === updatedStatus.trackingStatusId
          );

          if (!isStatusExist) {
            getAllCarrierTrackingStatusForSelection();
          }
        }
      }
    });
  };
  // for call method in parent
  useImperativeHandle(ref, () => ({
    Refresh() {
      handleRefresh();
    },
  }));
  const columns = [
    {
      field: "OrderNo",
      headerName: (
        <Stack direction={"column"}>
          <Box sx={{ fontWeight: "bold" }}>
            {LanguageReducer?.languageType?.ORDER_TEXT + " No."} / Ref No.
          </Box>
        </Stack>
      ),
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack flexDirection={"row"} alignItems={"center"}>
            {infoModal.loading[params.row.OrderNo] ? (
              <CircularProgress size={20} />
            ) : (
              <Stack sx={{ textAlign: "" }} direction={"column"}>
                <CodeBox
                  title={params.row.OrderNo}
                  onClick={(e) => {
                    handleGetShipmentInfoByOrderNo(params.row.OrderNo);
                    setIsNoteAdded((prev) => ({
                      ...prev,
                      orderNo: params.row.OrderNo,
                    }));
                  }}
                  copyBtn
                />
                {params?.row?.RefNo && (
                  <CodeBox title={params?.row?.RefNo} color={Colors.purple} />
                )}
                <Box
                  sx={{
                    color: `${!params.row?.CarrierId ? Colors.danger : ""}`,
                    fontSize: "10px",
                  }}
                >
                  {getCarrierText(params?.row?.OrderNo)}
                </Box>
                {!params?.row?.RefNo && (
                  <Box sx={{ opacity: "0" }}>
                    <CodeBox title={"..."} color={Colors.purple} />
                  </Box>
                )}
              </Stack>
            )}
          </Stack>
        );
      },
    },
    {
      field: "Store",
      // headerAlign: "center",
      // align: "center",
      headerName: <Box sx={{ fontWeight: "600" }}>Store Info.</Box>,
      minWidth: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box disableRipple>
            <>
              <Box>{params.row.StoreName}</Box>
              <Box>
                <DialerBox phone={params.row.CustomerServiceNo} />
              </Box>
              <Box sx={{ fontSize: "10px" }}>{params.row?.SaleChannelName}</Box>
            </>
          </Box>
        );
      },
    },
    {
      field: "DropOfAddress",
      // headerAlign: "center",
      // align: "center",
      headerName: <Box sx={{ fontWeight: "600" }}>Customer Info.</Box>,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box display={"flex"} flexDirection={"column"} disableRipple>
            <>
              <Box sx={{ fontWeight: "bold" }}>
                {params.row.CustomerName}
                <DescriptionBoxWithChild>
                  <TableContainer>
                    <Table sx={{ minWidth: 275 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "11px",
                              padding: "5px",
                              // width: "110px",
                            }}
                            align="left"
                          >
                            Name
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "11px",
                              padding: "5px",
                            }}
                          >
                            Mobile
                          </TableCell>
                          <TableCell
                            sx={{
                              fontWeight: "bold",
                              fontSize: "11px",
                              padding: "5px",
                              // width: "150px",
                            }}
                          >
                            Address
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          key={params.row.CustomerName}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: "7px",
                              fontSize: "11px",
                            }}
                            align="left"
                          >
                            {params.row.CustomerName}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "7px", fontSize: "11px" }}
                            align="right"
                          >
                            <DialerBox phone={params.row.Mobile1} />
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "7px",
                              fontSize: "11px",
                              // width: "150px",
                            }}
                          >
                            {params.row.CustomerFullAddress}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DescriptionBoxWithChild>
              </Box>
            </>
          </Box>
        );
      },
    },
    {
      field: "Payment",
      headerAlign: "center",
      align: "center",
      headerName: <Box sx={{ fontWeight: "600" }}>Payment Status</Box>,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            sx={{ textAlign: "center" }}
            disableRipple
          >
            <>
              <Box sx={{ fontWeight: "bold" }}>{params.row.PaymentMethod}</Box>
              <StatusBadge
                title={params.row.PaymentStatus}
                color={
                  params.row.PaymentStatus === "Unpaid" ? "#fff;" : "#fff;"
                }
                bgColor={
                  params.row.PaymentStatus === "Unpaid"
                    ? "#dc3545;"
                    : "#28a745;"
                }
              />
            </>
          </Box>
        );
      },
    },
    {
      field: "FulfillmentStatus",
      headerAlign: "center",
      align: "center",
      headerName: <Box sx={{ fontWeight: "600" }}>Fulfillment Status</Box>,
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            sx={{ textAlign: "center" }}
            disableRipple
          >
            <>
              {params.row.OrderTypeName != "Regular" && (
                <StatusBadge
                  title={params.row.FullFillmentStatus}
                  color={"#fff;"}
                  bgColor={
                    params.row?.FullFillmentStatusId ==
                    EnumFullFillmentStatus.UnFulfillment
                      ? "#dc3545;"
                      : "#28a745;"
                  }
                />
              )}
            </>
            <Box>
              {UtilityClass.convertUtcToLocalAndGetDate(
                params.row?.FulFilledDate
              )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "OrderTypeName",
      headerAlign: "center",
      align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.ORDER_TYPE_TEXT}
        </Box>
      ),
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction={"column"}>
            <StatusBadge
              title={params.row.OrderTypeName}
              color="#1E1E1E;"
              bgColor="#EAEAEA"
            />
            <Box>
              {UtilityClass.convertUtcToLocalAndGetDate(params.row.OrderDate)}
            </Box>
          </Stack>
        );
      },
    },
    {
      field: "TrackingStatus",
      headerAlign: "center",
      align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {LanguageReducer?.languageType?.TRACKING_STATUS_TEXT}
        </Box>
      ),
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Box className={"flex_center"} py={1}>
            <Box display="flex" flexDirection="column">
              <StatusBadge
                title={row.TrackingStatus}
                color="#1E1E1E;"
                bgColor="#EAEAEA"
                showBtn={!row?.IsClientCarrier && row?.CarrierId != null}
                btnLoading={refreshCarrierStatusLoading[row.OrderId]}
                btnOnClick={() => handleRefresh([row.OrderNo])}
              />
              {row?.CarrierLastUpdateDateTime && (
                <Box sx={{ textAlign: "center", display: "inline" }}>
                  <Typography fontSize="10px">
                    {UtilityClass.convertUtcToLocalAndGetTime(
                      row?.CarrierLastUpdateDateTime
                    )}
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "ItemsCount",
      headerAlign: "center",
      align: "center",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.ITEM_TEXT +
            " " +
            LanguageReducer?.languageType?.COUNT_TEXT}
        </Box>
      ),
      minWidth: 90,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <Box className={"flex_center"} flexDirection={"column"}>
              {orderIteminfoModal?.loading[params.row.OrderNo] ? (
                <CircularProgress size={20} />
              ) : (
                <CodeBox
                  title={params.row.ItemsCount}
                  onClick={(e) => {
                    handleGetShipmentItemInfoByOrderNo(params.row.OrderNo);
                  }}
                  eyeBtn={true}
                />
              )}
            </Box>
          </>
        );
      },
    },

    {
      field: "VAT",
      headerAlign: "center",
      align: "center",
      headerName: <Box sx={{ fontWeight: "600" }}>{" VAT"}</Box>,
      minWidth: 60,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            <Box sx={{ textAlign: "center" }}>{amountFormat(row.VAT)} </Box>{" "}
          </>
        );
      },
    },
    {
      field: "Discount",
      ...rightColumn,
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.DISCOUNT_TEXT}
        </Box>
      ),
      minWidth: 80,
      flex: 1,
      renderCell: ({ row }) => {
        return <>{amountFormat(row.Discount)}</>;
      },
    },
    {
      field: "Amount",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.AMOUNT_TEXT}
        </Box>
      ),
      minWidth: 120,
      flex: 1,
      ...rightColumn,
      renderCell: (params) => {
        return <Box>{amountFormat(params.row.Amount)}</Box>;
      },
    },
  ];

  const [selectionModel, setSelectionModel] = useState([]);
  const { currentPage, pageSize, handlePageChange, handlePageSizeChange } =
    usePagination(0, 10);
  const handleSelectedRow = (oNos) => {
    getOrdersRef.current = oNos;
    setSelectionModel(oNos);
    props.setSelectionModel(oNos);
    setShowActionToolbarButtons(checkRefArrayValue(oNos));
  };
  ////////////
  useEffect(() => {
    if (resetRowRef && resetRowRef.current) {
      getOrdersRef.current = [];
      resetRowRef.current = false;
      setSelectionModel([]);
    }
  }, [resetRowRef.current]);

  return (
    <Box sx={styleSheet.allOrderTable}>
      <DataGridProComponent
        rows={allOrders}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.OrderNo}
        selectionModel={selectionModel}
        onSelectionModelChange={(oNo) => handleSelectedRow(oNo)}
        checkboxSelection
        rowsCount={allOrdersCount}
        paginationChangeMethod={getAllOrders}
        paginationMethodUrl={EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.url}
        defaultRowsPerPage={EnumChangeFilterModelApiUrls.GET_ALL_ORDERS.length}
        height={windowHeight - 95 - 64 - 52}
      />
      <Menu
        anchorEl={anchorEl}
        id="power-search-menu"
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ width: "200px" }}>
          <List disablePadding>
            <ListItem
              disablePadding
              onClick={() => {
                handleGetShipmentInfoByOrderNo();
                setAnchorEl(null);
              }}
            >
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <DisplaySettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Order Detail" />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon sx={{ minWidth: "30px" }}>
                  <DeleteOutlineIcon />
                </ListItemIcon>
                <ListItemText primary="Delete Order" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Menu>
      {infoModal.data?.result && (
        <OrderDetailModal
          data={infoModal?.data?.result}
          open={infoModal.open}
          onClose={() => setInfoModal((prev) => ({ ...prev, open: false }))}
          isSendNotification={false}
          isUpdateOrderBtn={true}
          printCarrier={infoModal?.data?.result?.order?.CarrierTrackingNo}
          modelType={EnumDetailsModalType.ORDER}
          setIsNoteAdded={setIsNoteAdded}
          isNoteAdded={isNoteAdded}
        />
      )}
      {orderIteminfoModal.data?.result && (
        <OrderItemDetailModal
          onClose={() =>
            setOrderIteminfoModal((prev) => ({ ...prev, open: false }))
          }
          data={orderIteminfoModal?.data?.result}
          open={orderIteminfoModal.open}
        />
      )}
    </Box>
  );
}
export default forwardRef(OrderList);
