import { makeStyles } from "@material-ui/core/styles";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import SendIcon from "@mui/icons-material/Send";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import CustomAutocompleteField from "../../../.reUseableComponents/TextField/CustomAutocompleteField";
import CustomRHFPhoneInput from "../../../.reUseableComponents/TextField/CustomRHFPhoneInput";
import CustomRHFReactDatePickerInput from "../../../.reUseableComponents/TextField/CustomRHFReactDatePickerInput";
import RHFCustomAutocompleteField from "../../../.reUseableComponents/TextField/RHFCustomAutocompleteField";
import {
  DeleteOrderItemById,
  GetAllCountry,
  GetAllRegionbyCountryId,
  GetChannelListByStoreIdForSelection,
  GetCityByRegionId,
  GetProductStocksForSelection,
  GetStoresForSelection,
  GetValidateClientPPActivate,
  UpdateOrder,
  UpdateOrderWithInvoice,
} from "../../../api/AxiosInterceptors";
import { getAllStationLookupFunc } from "../../../apiCallingFunction";
import "../../../assets/styles/hideInputArrowsStyles.css";
import { styleSheet } from "../../../assets/styles/style";
import AddProductModal from "../../../components/modals/orderModals/AddProductModal";
import GoogleMapWithSearch from "../../../components/modals/resuseAbleModals/GoogleMapWithSearch";
import UtilityClass from "../../../utilities/UtilityClass";
import {
  EnumNavigateState,
  EnumOptions,
  EnumOrderType,
  EnumPaymentMethod,
  EnumPaymentStatus,
} from "../../../utilities/enum";
import {
  ActionButtonCustom,
  CrossIconButton,
  CustomColorLabelledOutline,
  DataGridRenderGreyBox,
  GridContainer,
  GridItem,
  PaymentAmountBox,
  PaymentAmountTextField,
  PaymentMethodBox,
  PaymentMethodCheckbox,
  PaymentTaxBox,
  PaymentTotalBox,
  amountFormat,
  decimalFormat,
  handleCopyToClipBoard,
  placeholders,
  truncate,
  useGetNavigateState,
  useMapAutocompleteSetter,
  useSetNumericInputEffect,
  getSelectedValueFromStringOrObject,
  getSelectedOrInputValueForApi,
  useGetAllClientTax,
  calculateSubtotalValue,
  getLowerCase,
} from "../../../utilities/helpers/Helpers";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import Colors from "../../../utilities/helpers/Colors";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import { calculateVatValue } from "../createRegularOrder";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  SchemaTextField,
  addressSchemaEnum,
  useGetAddressSchema,
} from "../../../utilities/helpers/addressSchema";
import { inputTypesEnum } from "../../../.reUseableComponents/Modal/ConfigSettingModal";

const useStyles = makeStyles({
  customTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "6px 0px 6px 16px",
      textAlign: "center",
    },
  },
});
const EnumOrderPlaceButton = Object.freeze({
  Confirm: 1,
  ConfirmAndNew: 2,
  ConfirmAndHandleInvoice: 2,
});
function EditFulFillableForm(props) {
  const { preloadedValues, orderData } = props;
  const classes = useStyles();
  const location = useLocation();

  const { data } = location.state || {};
  const [open, setOpen] = useState(false);
  const [isProductFetching, setIsProductFetching] = useState(false);

  const [openFromLinkLocationModal, setOpenFromLinkLocationModal] =
    useState(false);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [productStocksForSelection, setProductStocksForSelection] = useState(
    []
  );
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [storesForSelection, setStoresForSelection] = useState([]);
  const [selectedPMOption, setselectedPMOption] = useState(1);
  const [storesChannelForSelection, setStoresChannelForSelection] = useState(
    []
  );
  const [load, setLoad] = useState(false);
  const [productStations, setProductStations] = useState([]);
  const [qtySelectedStation, setQtySelectedStation] = useState();
  const [allCountries, setAllCountries] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [totalItemPrice, setTotalItemPrice] = useState(0);
  const [vatValue, setVatValue] = useState([]);
  const [subtotal, setSubtotal] = useState(0);

  const [discount, setDiscount] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [tax, setTax] = useState(5);
  const [note, setNote] = useState("");
  const [finalNote, setFinalNote] = useState("");
  const [remarks, setRemarks] = useState("");
  const [finalRemarks, setFinalRemarks] = useState("");
  const [isStripeSettingExist, setStripeSettingExist] = useState(false);
  const [isLoadingForConfirm, setIsLoadingForConfirm] = useState(false);
  const [isLoadingForConfirmAndNewOrder, setIsLoadingForConfirmAndNewOrder] =
    useState(false);
  const [isEditOrder, setIsEditOrder] = useState(false);
  const [
    isLoadingForConfirmAndSendInvoiceOrder,
    setIsLoadingForConfirmAndSendInvoiceOrder,
  ] = useState(false);
  const [selectedOrderPlaceButtom, setSelectedOrderPlaceButtom] =
    useState(false);
  const [paymentLinks, setPaymentLinks] = useState({
    show: false,
    paymentUrl: "",
    invoicePdf: "",
    showCrossIcon: true,
  });
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    control,
    unregister,
  } = useForm({ defaultValues: preloadedValues });

  const {
    selectedAddressSchema,
    addressSchemaSelectData,
    addressSchemaInputData,
    showMoreInfoBtn,
    handleAddRemoveMoreInfoFields,
    handleSetSchema,
    handleChangeInputAddressSchema,
    handleChangeSelectAddressSchemaAndGetOptions,
    handleSetSchemaValueForUpdate,
  } = useGetAddressSchema();

  useWatch({
    name: "country",
    control,
  });
  useWatch({
    name: "region",
    control,
  });
  useWatch({
    name: "city",
    control,
  });
  useWatch({
    name: "store",
    control,
  });
  const { allClientTax, loading } = useGetAllClientTax([]);
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  let getAllStationLookup = async () => {
    let data = await getAllStationLookupFunc();
    if (data.length > 0) {
      setProductStations(data);
    }
  };
  useEffect(() => {
    getAllStationLookup();
  }, []);
  let getStoresForSelection = async () => {
    let res = await GetStoresForSelection();
    //console.log("getStoresForSelection", res.data);
    setProductStocksForSelection([]);
    setValue("store", null);

    setStoresForSelection(res.data.result);
  };
  useEffect(() => {
    if (productStations.length > 0) {
      let selectedStation = productStations[0];
      if (orderData) {
        selectedStation = productStations?.find(
          (x) => x.productStationId == orderData?.order?.stationId
        );
        selectedStation = selectedStation;
      }
      setQtySelectedStation(selectedStation);
    }
  }, [productStations]);
  const getChannelListByStoreIdForSelection = async () => {
    setValue("storeChannel", null);

    if (getValues("store").storeId) {
      let res = await GetChannelListByStoreIdForSelection(
        getValues("store").storeId
      );
      // console.log("getStoresForSelection", res.data);
      setStoresChannelForSelection(res.data.result || []);
    }
  };
  let getValidateClientPPActivate = async () => {
    let res = await GetValidateClientPPActivate();
    //console.log("getValidateClientPPActivate", res.data);
    if (res.data.isSuccess) {
      setStripeSettingExist(res.data.result.isValidate);
    } else {
      setStripeSettingExist(false);
    }
  };
  useEffect(() => {
    getStoresForSelection();
    getValidateClientPPActivate();
  }, []);
  useEffect(() => {
    if (
      getValues("store") !== null &&
      getValues("store") !== "" &&
      getValues("store") !== undefined
    ) {
      getChannelListByStoreIdForSelection();
    }
  }, [getValues("store")]);

  let getAllCountry = async () => {
    let res = await GetAllCountry({});
    const _countries = res.data.result;
    setAllCountries(_countries);
  };

  useEffect(() => {
    getAllCountry();
  }, []);
  const [autocomplete, setAutocomplete] = useState(null);
  const getTotalAmount = () => {
    let total = 0;
    for (let index = 0; index < selectedProducts.length; index++) {
      total =
        total +
        ((selectedProducts[index].newPrice / 1 -
          selectedProducts[index].discount / 1) *
          selectedProducts[index].newQuantity) /
          1;
    }
    return total;
  };
  const prepareCreateOrderData = (data) => {
    let amount = 0;
    let weight = 0;
    let itemValue = 0;
    let description = [];
    let orderItems = [];
    for (let index = 0; index < selectedProducts.length; index++) {
      let ditem = `${selectedProducts[index].SKU}_${selectedProducts[index].newQuantity}`;

      // amount =
      //   amount +
      //   (selectedProducts[index].newPrice - selectedProducts[index].discount);
      weight = weight + selectedProducts[index].Weight;
      // description = `${description},${selectedProducts[index].Sku}_${
      //   selectedProducts[index].newQuantity
      //     ? selectedProducts[index].newQuantity
      //     : selectedProducts[index].QuantityAvailable
      // }`;
      description.push(ditem);
      itemValue =
        itemValue +
        (selectedProducts[index].newPrice - selectedProducts[index].discount) *
          selectedProducts[index].newQuantity;
      orderItems[index] = {
        orderItemId: selectedProducts[index]?.orderItemId || "",
        productId: selectedProducts[index].ProductId,
        productStockId: selectedProducts[index].ProductStockId,
        price: selectedProducts[index].newPrice,
        description: ditem,
        remarks: "",
        quantity: selectedProducts[index].newQuantity,
        discount: selectedProducts[index].discount,
      };
    }
    amount =
      Number(totalItemPrice) +
      calculateVatValue(vatValue) +
      Number(shipping) -
      Number(discount);

    if (orderItems.length == 0) {
      errorNotification("Please add product to proceed");
      return false;
    } else {
      //  console.log("orderItems", orderItems);
      const param1 = {
        orderId: orderData?.order?.orderId,
        storeId: data.store.storeId,
        orderTypeId: EnumOrderType.FullFillable,
        description: description.join(","),
        orderDate: UtilityClass.getFormatedDateWithoutTime(data?.orderDate),
        remarks: remarks,
        amount: amount,
        cShippingCharges: shipping,
        paymentStatusId:
          EnumPaymentMethod.Prepaid == selectedPMOption
            ? EnumPaymentStatus.Paid
            : EnumPaymentStatus.Unpaid,
        weight: weight,
        itemValue: itemValue,
        orderRequestVia: 1,
        paymentMethodId: selectedPMOption,
        stationId: orderData?.order?.stationId,
        discount: discount,
        vat: 0,
        refNo: data?.refNo,
        channelId: data.storeChannel?.id || 0,
        orderNote: {
          note: note,
        },
        orderAddress: {
          countryId: selectedAddressSchema.country,
          cityId: selectedAddressSchema.city,
          areaId: selectedAddressSchema.area,
          streetAddress: selectedAddressSchema.streetAddress,
          streetAddress2: selectedAddressSchema.streetAddress2,
          houseNo: selectedAddressSchema.houseNo,
          buildingName: selectedAddressSchema.buildingName,
          landmark: selectedAddressSchema.landmark,
          provinceId: selectedAddressSchema.province,
          pinCodeId: selectedAddressSchema.pinCode,
          stateId: selectedAddressSchema.state,
          zip: selectedAddressSchema.zip,
          latitude: data.latitude ? data.latitude : null,
          longitude: data.longitude ? data.longitude : null,
          orderAddressId: orderData?.orderAddress?.orderAddressId,
          customerName: data.customerName,
          email: data.email,
          mobile1: UtilityClass.getFormatedNumber(data.mobile1),
          mobile2: UtilityClass.getFormatedNumber(data.mobile2),
        },
        orderTaxes: orderData?.orderTax
          ?.map((tax) => {
            return {
              orderTaxId: tax.orderTaxId,
              ClientTaxId: tax.clientTaxId,
              taxValue: Number(vatValue[tax.clientTaxId]),
            };
          })
          .filter((dt) => dt !== undefined),
        orderItems: orderItems,
      };
      return param1;
    }
  };
  const handleConfirmOrder = async (data) => {
    const param1 = prepareCreateOrderData(data);
    // console.log("params", param1);
    if (param1) {
      setIsLoadingForConfirm(true);
      setSelectedOrderPlaceButtom(EnumOrderPlaceButton.Confirm);

      UpdateOrder(param1)
        .then((res) => {
          // console.log("res:::", res);
          if (!res?.data?.isSuccess) {
            errorNotification("Unable updated order");
            errorNotification(res?.data?.customErrorMessage);
          } else {
            successNotification("Order updated successfully");
            navigate("/orders-dashboard");
          }
        })
        .catch((e) => {
          // console.log("e", e);
          errorNotification("Unable updated order");
        })
        .finally(() => {
          setIsLoadingForConfirm(false);
          setSelectedOrderPlaceButtom(null);
        });
    }
  };
  const handleSendInvoice = async (data) => {
    const param1 = prepareCreateOrderData(data);
    if (param1) {
      //  console.log("params", param1);
      setIsLoadingForConfirmAndSendInvoiceOrder(true);
      setSelectedOrderPlaceButtom(EnumOrderPlaceButton.ConfirmAndHandleInvoice);

      UpdateOrderWithInvoice(param1)
        .then((res) => {
          // console.log("res:::", res);
          if (!res?.data?.isSuccess) {
            errorNotification("Unable to update order");
            errorNotification(res?.data?.customErrorMessage);
          } else {
            successNotification("Order updated successfully");
            const data = res.data.result;
            setPaymentLinks((prev) => ({
              ...prev,
              show: true,
              paymentUrl: data?.hostedInvoiceUrl,
              invoicePdf: data?.invoicePdf,
            }));
          }
        })
        .catch((e) => {
          //  console.log("e", e);
          errorNotification("Unable to update order");
        })
        .finally(() => {
          setSelectedOrderPlaceButtom(null);
          setIsLoadingForConfirmAndSendInvoiceOrder(false);
        });
    }
  };

  const handleRestForm = () => {
    setFinalNote("");
    setNote("");
    setRemarks("");
    setFinalRemarks("");
    setDiscount(0);
    setShipping(0);
    setTax(5);
    setSelectedProducts([]);
    setTotalItemPrice(0);
    reset();
    setValue("mobile1", UtilityClass.getDefaultCountryCode());
    setValue("mobile2", UtilityClass.getDefaultCountryCode());
  };

  const handleOnchangeProduct = (e, index) => {
    let newSelectedProducts = [...selectedProducts];
    if (parseInt(e.target.value) > 0) {
      newSelectedProducts[index] = {
        ...newSelectedProducts[index],
        newQuantity: parseInt(e.target.value),
        newPrice: Number(e.target.value) * newSelectedProducts[index].Price,
        discount: 0,
      };
      setSelectedProducts(newSelectedProducts);
    }
  };
  const handleFocus = (event) => event.target.select();
  const paymentMethodoptions = [
    { id: 1, label: "Prepaid" },
    { id: 2, label: "Cash On Delivery" },
  ];

  const handleCheckboxChange = (optionId) => {
    setselectedPMOption(optionId);
  };
  const handleOnClickDecrement = (e, index) => {
    let newSelectedProducts = [...selectedProducts];
    let newQuantity;
    if (newSelectedProducts[index].newQuantity > 1) {
      newQuantity = newSelectedProducts[index].newQuantity
        ? newSelectedProducts[index].newQuantity - 1
        : newSelectedProducts[index].QuantityAvailable - 1;
      newSelectedProducts[index] = {
        ...newSelectedProducts[index],
        newQuantity: newQuantity,
        newPrice: Number(newQuantity) * newSelectedProducts[index].Price,
        discount: 0,
      };
      setSelectedProducts(newSelectedProducts);
    }
    setIsFirstLoad(false);
  };
  const handleOnClickIncrement = (e, index) => {
    let newSelectedProducts = [...selectedProducts];
    let newQuantity;
    if (
      newSelectedProducts[index].newQuantity <=
      newSelectedProducts[index].QuantityAvailable
    ) {
      newQuantity = newSelectedProducts[index].newQuantity
        ? newSelectedProducts[index].newQuantity + 1
        : newSelectedProducts[index].QuantityAvailable + 1;
      newSelectedProducts[index] = {
        ...newSelectedProducts[index],
        newQuantity: newQuantity,
        newPrice:
          Number(newQuantity) * Number(newSelectedProducts[index].Price),
        discount: 0,
      };
      setSelectedProducts(newSelectedProducts);
    }
    setIsFirstLoad(false);
  };
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  useEffect(() => {
    let _totalItemPrice = 0;
    let _totaldiscount = 0;
    selectedProducts.forEach((data) => {
      _totalItemPrice += Number(data.newPrice);
      _totaldiscount += Number(data.discount);
    });
    if (!isFirstLoad) {
      setTotalItemPrice(_totalItemPrice);
    } else {
      setTotalItemPrice(
        calculateSubtotalValue(
          orderData?.order?.discount,
          orderData?.order?.cShippingCharges,
          orderData?.orderTax,
          orderData?.order?.amount
        )
      );
    }
    // setDiscount(_totaldiscount);
  }, [selectedProducts]);
  ///////////
  useEffect(() => {
    if (storesForSelection?.length > 0) {
      let defData = storesForSelection.find(
        (x) => x.storeId == preloadedValues?.storeId
      );
      setValue("store", defData);
    }
  }, [storesForSelection]);
  useEffect(() => {
    if (storesChannelForSelection?.length > 0) {
      let defData = storesChannelForSelection.find(
        (x) => x.id == orderData?.order?.saleChannelConfigId
      );
      setValue("storeChannel", defData);
    }
  }, [storesChannelForSelection]);

  useEffect(() => {
    if (orderData) {
      let order = orderData?.order;
      setDiscount(order?.discount);
      setShipping(order?.cShippingCharges);
      setFinalRemarks(order?.remarks);
      setRemarks(order?.remarks);
      handleCheckboxChange(order?.paymentMethodId);
      setNote(orderData?.orderNote?.note);
      setFinalNote(orderData?.orderNote?.note);
      setSubtotal(
        calculateSubtotalValue(
          orderData?.order?.discount,
          orderData?.order?.cShippingCharges,
          orderData?.orderTax,
          orderData?.order?.amount
        )
      );

      if (orderData) {
        if (orderData?.orderItems) {
          getProductStocksForSelection();
        }
      }

      setIsEditOrder(true);
    }
  }, [orderData]);
  let getProductStocksForSelection = async () => {
    if (isEditOrder) {
      //let do somework regarding edit order
    } else {
      setSelectedProducts([]);
    }
    let stationId =
      qtySelectedStation?.productStationId || orderData?.order.stationId;
    setIsProductFetching(true);

    let res = await GetProductStocksForSelection(
      stationId,
      getValues("store")?.storeId || orderData?.order?.storeId
    );

    // console.log("data:::", res.data);
    if (res.data.result != null && res.data.result?.length > 0) {
      for (let index = 0; index < res.data?.result.length; index++) {
        //get selected object against station

        let obj = orderData?.orderItems?.find(
          (x) => x.productStockId == res.data.result[index].ProductStockId
        );
        //orderItemId

        res.data.result[index].checked = obj ? true : false;
        res.data.result[index].isRemove =
          obj && obj?.orderItemId ? true : false; //if edit case then remove row
        res.data.result[index].discount = 0;
        res.data.result[index].newPrice = res.data.result[index].Price;
        res.data.result[index].newQuantity = 1;
        res.data.result[index].stationId = qtySelectedStation?.productStationId;
        // res.data.result[index].QuantityAvailable;
      }

      /////////
      let preloadedProducts = [];
      orderData?.orderItems.forEach((item, i) => {
        let value = res.data.result?.find(
          (x) => x.ProductStockId == item.productStockId
        );
        if (value) {
          value["orderItemId"] = item.orderItemId;
          value["checked"] = true;
          value["Price"] = item.price;
          value["newPrice"] = item.price;
          value["newQuantity"] = item.quantity;
          value["discount"] = item.discount;
          preloadedProducts.push(value);
        }
      });
      setSelectedProducts(preloadedProducts);
      setTotalItemPrice(orderData?.order?.actualAmount);
      ///////////
      setProductStocksForSelection(res.data.result);
    } else {
      errorNotification("No product found agains't selected store.");
    }

    setIsProductFetching(false);
  };
  const handleOnClickProduct = async () => {
    await getProductStocksForSelection();
    //open modal
    setOpen(true);
  };
  const handleDelete = async (value) => {
    value.checked = false;
    if (value?.orderItemId) {
      await handleDeleteItemFromDb(value);
    }
    let product = selectedProducts.filter(
      (item) => item.ProductStockId !== value.ProductStockId
    );
    setSelectedProducts(product);
  };
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDeleteItemFromDb = async (item) => {
    // console.log("handleDeleteItemFromDb", item);
    let param = {
      orderId: orderData?.order?.orderId,
      orderItemId: item?.orderItemId,
    };
    setIsDeleting(true);
    DeleteOrderItemById(param)
      .then((res) => {
        if (!res.data.isSuccess) {
          let jsonData = res.data.errors;

          UtilityClass.showErrorNotificationWithDictionary(jsonData);
        } else {
          successNotification("Item delete successfully");
        }
      })
      .catch((e) => {
        setIsDeleting(false);
        let res = e.response;
        if (!res.data.isSuccess) {
          let jsonData = res.data.errors;

          UtilityClass.showErrorNotificationWithDictionary(jsonData);
        } else {
          errorNotification(
            LanguageReducer?.languageType
              ?.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_TOAST
          );
        }

        // console.log("e", e);
      });
  };

  const { navigateStateData } = useGetNavigateState(
    EnumNavigateState.EDIT_ORDER.pageName,
    false
  );
  const disabledInput = navigateStateData
    ? navigateStateData.disabledInput
    : false;

  useSetNumericInputEffect([selectedProducts]);
  useMapAutocompleteSetter(
    autocomplete,
    allCountries,
    allRegions,
    allCities,
    setValue
  );

  useEffect(() => {
    (async () => {
      setValue("mobile1", null);
      setValue("mobile2", null);
      await handleSetSchemaValueForUpdate(orderData.orderAddress, setValue);
      setValue("mobile1", preloadedValues.mobile1);
      setValue("mobile2", preloadedValues.mobile2);
    })();
  }, []);

  return (
    <Box
      sx={{
        ...styleSheet.pageRoot,
        "& .MuiFormLabel-asterisk": {
          color: "red !important",
        },
      }}
    >
      <form>
        {/* <Container maxWidth="lg" fixed sx={{ paddingLeft: "0px" }}> */}
        <div style={{ padding: "10px" }}>
          <GridItem textAlign={"right"}>
            <LoadingButton
              onClick={() => {
                navigate("/orders-dashboard");
              }}
              sx={styleSheet.placeOrderButton}
              variant="contained"
            >
              Order Dashboard
            </LoadingButton>
          </GridItem>
          <GridContainer>
            <GridItem xs={7}>
              <GridContainer>
                <GridItem xs={12}>
                  <CustomColorLabelledOutline
                    label={LanguageReducer?.languageType?.STORE_TEXT}
                  >
                    <Grid display={"flex"} gap={1}>
                      <Grid md={6} lg={6} sm={12}>
                        <InputLabel required sx={styleSheet.inputLabel}>
                          {LanguageReducer?.languageType?.STORE_TEXT}
                        </InputLabel>
                        <SelectComponent
                          disabled
                          name="store"
                          control={control}
                          options={storesForSelection}
                          isRHF={true}
                          required={true}
                          getOptionLabel={(option) => option?.storeName}
                          optionLabel={EnumOptions.STORE.LABEL}
                          optionValue={EnumOptions.STORE.VALUE}
                          {...register("store", {
                            required: {
                              value: true,
                            },
                          })}
                          value={getValues("store")}
                          onChange={(event, newValue) => {
                            const resolvedId = newValue ? newValue : null;
                            setValue("store", resolvedId);
                          }}
                          errors={errors}
                        />
                      </Grid>
                      <Grid md={6} lg={6} sm={12}>
                        <InputLabel sx={styleSheet.inputLabel}>
                          Sale Channel
                        </InputLabel>
                        <SelectComponent
                          disabled
                          name="storeChannel"
                          control={control}
                          options={storesChannelForSelection}
                          isRHF={false}
                          required={false}
                          optionLabel={EnumOptions.STORE_CHANNEL.LABEL}
                          optionValue={EnumOptions.STORE_CHANNEL.VALUE}
                          {...register("storeChannel")}
                          value={getValues("storeChannel")}
                          onChange={(event, newValue) => {
                            const resolvedId = newValue ? newValue : null;
                            setValue("storeChannel", resolvedId);
                          }}
                          // errors={errors}
                        />
                      </Grid>
                    </Grid>
                    <Grid display={"flex"} gap={1} mt={1}>
                      {getValues("store")?.storeId &&
                      getValues("store")?.storeId > 0 ? (
                        <Grid item sm={12} md={6} lg={6}>
                          <InputLabel required sx={styleSheet.inputLabel}>
                            {"Station"}
                          </InputLabel>
                          <SelectComponent
                            disabled
                            name="qtySelectedStation"
                            options={productStations}
                            defaulValue={qtySelectedStation}
                            value={qtySelectedStation}
                            optionLabel={EnumOptions.SELECT_STATION.LABEL}
                            optionValue={EnumOptions.SELECT_STATION.VALUE}
                            onChange={(e, newValue) => {
                              const resolvedId = newValue ? newValue : null;
                              setQtySelectedStation(resolvedId);
                            }}
                          />
                        </Grid>
                      ) : null}
                      <Grid md={6} lg={6} sm={12}>
                        <InputLabel sx={styleSheet.inputLabel}>
                          Order Date
                        </InputLabel>
                        <CustomRHFReactDatePickerInput
                          name="orderDate"
                          disabled={disabledInput}
                          control={control}
                          // onChange={handleOnChange}
                          required
                          error={
                            LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT
                          }
                          // defaultValue={preloadedValues?.orderDate}
                        />
                      </Grid>
                    </Grid>
                    <Grid item md={12} sm={12}>
                      <InputLabel sx={styleSheet.inputLabel}>
                        Ref No.
                      </InputLabel>
                      <TextField
                        defaultValue={orderData?.order.refNo}
                        type="text"
                        placeholder={placeholders.refNo}
                        onFocus={handleFocus}
                        size="small"
                        fullWidth
                        variant="outlined"
                        id="refNo"
                        name="refNo"
                        {...register("refNo")}
                      />
                    </Grid>
                  </CustomColorLabelledOutline>
                </GridItem>
                {qtySelectedStation?.productStationId &&
                qtySelectedStation?.productStationId > 0 ? (
                  <GridItem xs={12}>
                    <CustomColorLabelledOutline
                      label={LanguageReducer?.languageType?.PRODUCTS_TEXT}
                    >
                      {!disabledInput && (
                        <Box textAlign={"right"}>
                          <ActionButtonCustom
                            loading={isProductFetching}
                            startIcon={<AddBoxIcon fontSize="small" />}
                            label={"Update Products"}
                            onClick={() => handleOnClickProduct()}
                            // sx={styleSheet.placeOrderButton}
                          />
                        </Box>
                      )}
                      <Box sx={{ overflow: "auto" }}>
                        <Box
                          sx={{
                            width: "100%",
                            display: "table",
                            tableLayout: "fixed",
                          }}
                        >
                          <Table size="small">
                            <TableHead
                              sx={{ ...styleSheet.orderProductHeading }}
                            >
                              {" "}
                              <TableRow>
                                <TableCell sx={{ fontWeight: "bold", p: 1 }}>
                                  {LanguageReducer?.languageType?.PRODUCT_TEXT}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "bold",
                                    p: 1,
                                    minWidth: 50,
                                  }}
                                  align="center"
                                >
                                  {"Comm. Qty"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "bold",
                                    p: 1,
                                    minWidth: 50,
                                  }}
                                  align="center"
                                >
                                  {"Avl. Qty"}
                                </TableCell>

                                <TableCell
                                  sx={{ fontWeight: "bold", p: 1 }}
                                  align="center"
                                >
                                  {LanguageReducer?.languageType?.QUANTITY_TEXT}
                                </TableCell>
                                <TableCell sx={{ fontWeight: "bold", p: 1 }}>
                                  {LanguageReducer?.languageType?.DISCOUNT_TEXT}
                                </TableCell>
                                <TableCell
                                  sx={{ fontWeight: "bold", p: 1 }}
                                  align="right"
                                >
                                  {LanguageReducer?.languageType?.TOTAL_TEXT}
                                </TableCell>
                                <TableCell
                                  sx={{ fontWeight: "bold", p: 1 }}
                                ></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {selectedProducts &&
                                selectedProducts.length > 0 &&
                                selectedProducts?.map((value, index) => {
                                  return (
                                    <TableRow key={index}>
                                      <TableCell sx={{ p: 0.5 }}>
                                        <Box
                                          display={"flex"}
                                          gap={1}
                                          alignItems={"center"}
                                        >
                                          <Avatar
                                            sx={{
                                              width: 60,
                                              height: 60,
                                              flexShrink: 0,
                                            }}
                                            variant="rounded"
                                            src={value.FeatureImage}
                                          />
                                          <Box flexShrink={0}>
                                            <Typography
                                              variant="h5"
                                              fontWeight={300}
                                            >
                                              {truncate(value.ProductName)}
                                            </Typography>
                                            <Typography
                                              variant="h6"
                                              fontWeight={300}
                                            >
                                              <DataGridRenderGreyBox
                                                title={truncate(
                                                  value.VarientOption,
                                                  25
                                                )}
                                              />
                                            </Typography>

                                            <Box
                                              sx={
                                                styleSheet.productItemBoxPrice
                                              }
                                            >
                                              {
                                                LanguageReducer?.languageType
                                                  ?.AED_TEXT
                                              }{" "}
                                              {amountFormat(value.Price)}
                                            </Box>
                                          </Box>
                                        </Box>
                                      </TableCell>
                                      <TableCell sx={{ p: 0.5 }} align="center">
                                        <b>{value.QuantityCommited}</b>
                                      </TableCell>
                                      <TableCell align="center" sx={{ p: 0.5 }}>
                                        <b>{value.QuantityAvailable}</b>
                                      </TableCell>

                                      <TableCell sx={{ p: 0.5 }} align="center">
                                        {" "}
                                        {disabledInput ? (
                                          <TextField
                                            disabled={disabledInput}
                                            size="small"
                                            type="number"
                                            onFocus={handleFocus}
                                            fullWidth
                                            InputProps={{
                                              step: "any",
                                              inputProps: {
                                                min: 0,
                                                max: selectedProducts[index]
                                                  .newPrice,
                                              },
                                            }}
                                            sx={{
                                              color: "white",
                                              width: "50px",
                                              border: "none",
                                              background: "transparent",
                                              "& .MuiInputBase-root": {
                                                height: 30,
                                              },
                                            }}
                                            value={
                                              value.newQuantity
                                                ? value.newQuantity
                                                : value.QuantityAvailable
                                            }
                                          />
                                        ) : (
                                          <Box className={"flex_center"}>
                                            <Box
                                              sx={
                                                styleSheet.qualityIncrementBox
                                              }
                                            >
                                              <IndeterminateCheckBoxOutlinedIcon
                                                onClick={(e) =>
                                                  handleOnClickDecrement(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                              <Box sx={{ fontSize: "17px" }}>
                                                <TextField
                                                  inputProps={{
                                                    style: {
                                                      width: "30px",
                                                      padding: "0px",
                                                      textAlign: "center",
                                                    },
                                                  }}
                                                  fullWidth
                                                  min={0}
                                                  type="number"
                                                  placeholder={
                                                    placeholders.quantity
                                                  }
                                                  onFocus={handleFocus}
                                                  size="small"
                                                  value={
                                                    value.newQuantity
                                                      ? value.newQuantity
                                                      : value.QuantityAvailable
                                                  }
                                                  onChange={(e) =>
                                                    handleOnchangeProduct(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </Box>
                                              <AddBoxOutlinedIcon
                                                onClick={(e) =>
                                                  handleOnClickIncrement(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                            </Box>
                                          </Box>
                                        )}
                                      </TableCell>

                                      <TableCell sx={{ p: 0.5 }}>
                                        <Box sx={styleSheet.productItemBox}>
                                          <TextField
                                            disabled={disabledInput}
                                            size="small"
                                            type="number"
                                            onFocus={handleFocus}
                                            fullWidth
                                            InputProps={{
                                              step: "any",
                                              inputProps: {
                                                min: 0,
                                                max: selectedProducts[index]
                                                  .newPrice,
                                              },
                                            }}
                                            sx={{
                                              color: "white",
                                              width: "50px",
                                              border: "none",
                                              background: "transparent",
                                              "& .MuiInputBase-root": {
                                                height: 30,
                                              },
                                            }}
                                            value={value.discount}
                                            defaultValue={value.discount}
                                            onChange={(e) => {
                                              let newSelectedProducts = [
                                                ...selectedProducts,
                                              ];
                                              newSelectedProducts[index] = {
                                                ...newSelectedProducts[index],
                                                discount:
                                                  eval(e.target.value) || 0,
                                                newPrice:
                                                  newSelectedProducts[index]
                                                    .Price *
                                                    newSelectedProducts[index]
                                                      .newQuantity -
                                                  (eval(e.target.value) || 0),
                                              };
                                              setSelectedProducts(
                                                newSelectedProducts
                                              );
                                              setIsFirstLoad(false);
                                            }}
                                          />
                                        </Box>
                                      </TableCell>
                                      <TableCell align="right" sx={{ p: 0.5 }}>
                                        <PaymentAmountTextField
                                          disabled={disabledInput}
                                          value={
                                            selectedProducts[index].newPrice
                                          }
                                          onChange={(e) => {
                                            let newSelectedProducts = [
                                              ...selectedProducts,
                                            ];
                                            newSelectedProducts[index] = {
                                              ...newSelectedProducts[index],
                                              newPrice: e.target.value,
                                            };
                                            setSelectedProducts(
                                              newSelectedProducts
                                            );
                                          }}
                                          width={118}
                                        />
                                      </TableCell>
                                      {!disabledInput && (
                                        <TableCell
                                          align="right"
                                          sx={{ p: 0.5 }}
                                        >
                                          <Button
                                            onClick={() => {
                                              handleDelete(value);
                                            }}
                                            sx={styleSheet.deleteProductButton}
                                            variant="outlined"
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </TableCell>
                                      )}
                                    </TableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </Box>
                      </Box>
                    </CustomColorLabelledOutline>
                  </GridItem>
                ) : null}
                <GridItem xs={12}>
                  <CustomColorLabelledOutline
                    label={LanguageReducer?.languageType?.CUSTOMER_TEXT}
                  >
                    <Grid container spacing={2}>
                      <Grid item md={6} sm={12}>
                        <InputLabel required sx={styleSheet.inputLabel}>
                          {LanguageReducer?.languageType?.CUSTOMER_NAME_TEXT}
                        </InputLabel>
                        <TextField
                          type="text"
                          placeholder={placeholders.name}
                          onFocus={handleFocus}
                          size="small"
                          fullWidth
                          disabled={disabledInput}
                          // onChange={(e) => { setOrderAddress({ ...orderAddress, customerName: e.target.value }) }}
                          variant="outlined"
                          id="customerName"
                          name="customerName"
                          {...register("customerName", {
                            required: {
                              value: true,
                              message:
                                LanguageReducer?.languageType
                                  ?.FIELD_REQUIRED_TEXT,
                            },
                            pattern: {
                              value: /^(?!\s*$).+/,
                              message:
                                LanguageReducer?.languageType
                                  ?.INPUT_SHOULD_NOT_BE_ONLY_SPACES,
                            },
                          })}
                          error={Boolean(errors.customerName)} // set error prop
                          helperText={errors.customerName?.message}
                        />
                      </Grid>
                      <Grid item md={6} sm={12}>
                        <InputLabel sx={styleSheet.inputLabel}>
                          {LanguageReducer?.languageType?.EMAIL_TEXT}{" "}
                        </InputLabel>
                        <TextField
                          disabled={disabledInput}
                          placeholder={placeholders.email}
                          onFocus={handleFocus}
                          type="email"
                          size="small"
                          id="email"
                          name="email"
                          fullWidth
                          variant="outlined"
                          {...register("email", {
                            // required: {
                            //   value: true,
                            //   message:
                            //     LanguageReducer?.languageType
                            //       ?.FIELD_REQUIRED_TEXT,
                            // },
                            pattern: {
                              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, // set pattern to match email format
                              message:
                                LanguageReducer?.languageType
                                  ?.INVALID_EMAIL_TOAST,
                            },
                          })}
                          error={Boolean(errors.email)} // set error prop
                          helperText={errors.email?.message}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <InputLabel required sx={styleSheet.inputLabel}>
                          {LanguageReducer?.languageType?.MOBILE_NUMBER_TEXT}
                        </InputLabel>
                        <CustomRHFPhoneInput
                          disabled={disabledInput}
                          error={
                            LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT
                          }
                          name="mobile1"
                          selectedCountry={getValues("country")?.mapCountryCode}
                          control={control}
                          required
                          borderRadius={"2px"}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <InputLabel sx={styleSheet.inputLabel}>
                          {LanguageReducer?.languageType?.CONTACT_NUMBER_TEXT}
                        </InputLabel>
                        <CustomRHFPhoneInput
                          disabled={disabledInput}
                          error={
                            LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT
                          }
                          name="mobile2"
                          selectedCountry={getValues("country")?.mapCountryCode}
                          control={control}
                          borderRadius={"2px"}
                          isContact={true}
                        />
                      </Grid>
                      <Grid item md={6} sm={6} xs={12}>
                        <InputLabel required sx={styleSheet.inputLabel}>
                          {LanguageReducer?.languageType?.SELECT_COUNTRY_TEXT}
                        </InputLabel>
                        <SelectComponent
                          disabled={disabledInput}
                          name="country"
                          control={control}
                          options={allCountries}
                          isRHF={true}
                          required={true}
                          optionLabel={EnumOptions.COUNTRY.LABEL}
                          optionValue={EnumOptions.COUNTRY.VALUE}
                          {...register("country", {
                            required: {
                              value: true,
                            },
                          })}
                          value={getValues("country")}
                          onChange={(event, newValue) => {
                            const resolvedId = newValue ? newValue : null;
                            handleSetSchema(
                              "country",
                              resolvedId,
                              setValue,
                              unregister
                            );
                          }}
                          errors={errors}
                        />
                      </Grid>
                      {[
                        ...addressSchemaSelectData,
                        ...addressSchemaInputData,
                      ].map((input, index, arr) => (
                        <Grid item md={6} sm={12}>
                          <SchemaTextField
                            loading={input.loading}
                            disabled={input.disabled}
                            isRHF={true}
                            type={input.type}
                            name={input.key}
                            required={input.required}
                            optionLabel={addressSchemaEnum[input.key]?.LABEL}
                            optionValue={addressSchemaEnum[input.key]?.VALUE}
                            register={register}
                            options={input.options}
                            errors={errors}
                            label={input.label}
                            value={getValues(input.key)}
                            showMoreInfoBtn={index + 1 === arr.length}
                            btnFlag={showMoreInfoBtn}
                            handleClickShowMoreInfoBtn={(val) =>
                              handleAddRemoveMoreInfoFields(val, setValue)
                            }
                            onChange={
                              getLowerCase(input.type) === inputTypesEnum.SELECT
                                ? (name, value) => {
                                    handleChangeSelectAddressSchemaAndGetOptions(
                                      input.key,
                                      index,
                                      value,
                                      setValue,
                                      input.key
                                    );
                                  }
                                : (e) => {
                                    handleChangeInputAddressSchema(
                                      input.key,
                                      e.target.value,
                                      setValue
                                    );
                                  }
                            }
                          />
                        </Grid>
                      ))}
                      <GridContainer>
                        <Grid item md={3} sm={3} xs={12}>
                          <InputLabel sx={styleSheet.inputLabel}>
                            {LanguageReducer?.languageType?.LONGITUDE_TEXT}
                          </InputLabel>
                          <TextField
                            disabled={disabledInput}
                            placeholder={placeholders.longitude}
                            onFocus={handleFocus}
                            type="text"
                            size="small"
                            id="longitude"
                            name="longitude"
                            fullWidth
                            variant="outlined"
                            {...register("longitude", {
                              // required: {
                              //   value: true,
                              //   message:
                              //     LanguageReducer?.languageType
                              //       ?.FIELD_REQUIRED_TEXT,
                              // },
                            })}
                            // error={Boolean(errors.longitude)} // set error prop
                            // helperText={errors.longitude?.message}
                          />
                        </Grid>
                        <Grid item md={3} sm={3} xs={12}>
                          <InputLabel sx={styleSheet.inputLabel}>
                            {LanguageReducer?.languageType?.LATITUDE_TEXT}
                          </InputLabel>
                          <TextField
                            disabled={disabledInput}
                            placeholder={placeholders.latitude}
                            onFocus={handleFocus}
                            type="text"
                            size="small"
                            id="latitude"
                            name="latitude"
                            fullWidth
                            variant="outlined"
                            // {...register("latitude", {
                            //   required: {
                            //     value: true,
                            //     message:
                            //       LanguageReducer?.languageType
                            //         ?.FIELD_REQUIRED_TEXT,
                            //   },
                            // })}
                            // error={Boolean(errors.latitude)} // set error prop
                            // helperText={errors.latitude?.message}
                          />
                        </Grid>
                        {!disabledInput && (
                          <Grid item md={3} sm={3} xs={12} alignSelf={"end"}>
                            <ActionButtonCustom
                              onClick={() => setOpenLocationModal(true)}
                              startIcon={<FmdGoodOutlinedIcon />}
                              label={
                                LanguageReducer?.languageType?.FROM_MAP_TEXT
                              }
                              height={{ ...styleSheet.fromMapButton }}
                            />
                          </Grid>
                        )}
                      </GridContainer>
                    </Grid>
                  </CustomColorLabelledOutline>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={5}>
              <GridContainer>
                <GridItem xs={12}>
                  <CustomColorLabelledOutline
                    label={LanguageReducer?.languageType?.PAYMENT_TEXT}
                  >
                    <Box display={"flex"} flexDirection={"column"} gap={1}>
                      <PaymentAmountBox
                        disabled={disabledInput}
                        title={"Sub Total"}
                        value={decimalFormat(totalItemPrice)}
                        onChange={(e) => {
                          const _totalItemPrice = Number(e.target.value);
                          setTotalItemPrice(_totalItemPrice);
                        }}
                      />
                      <PaymentAmountBox
                        disabled={disabledInput}
                        title={LanguageReducer?.languageType?.ADD_DISCOUNT}
                        value={discount}
                        onChange={(e) => {
                          setDiscount(Number(e.target.value));
                        }}
                      />
                      <PaymentAmountBox
                        disabled={disabledInput}
                        title={LanguageReducer?.languageType?.ADD_SHIPPING}
                        value={shipping}
                        onChange={(e) => {
                          setShipping(Number(e.target.value));
                        }}
                      />
                      {loading ? (
                        <Box className={"flex_center"}>
                          <CircularProgress size={24} />
                        </Box>
                      ) : (
                        allClientTax.map((_tax) => {
                          if (_tax.Active === true) {
                            return (
                              <PaymentTaxBox
                                title={_tax.Name}
                                value={_tax.Percentage}
                                getTaxValue={(val) => {
                                  setVatValue((prev) => ({
                                    ...prev,
                                    [_tax.ClientTaxId]: val,
                                  }));
                                }}
                                subtotal={totalItemPrice}
                              />
                            );
                          }
                        })
                      )}
                      <PaymentTotalBox
                        value={(
                          Number(totalItemPrice) +
                          calculateVatValue(vatValue) +
                          Number(shipping) -
                          Number(discount)
                        ).toFixed(2)}
                      />
                      <Divider />

                      <PaymentMethodBox
                        options={
                          <Box className={"flex_between"}>
                            {paymentMethodoptions.map((option) => (
                              <PaymentMethodCheckbox
                                disabled={disabledInput}
                                key={option.id}
                                checked={selectedPMOption === option.id}
                                onChange={() => handleCheckboxChange(option.id)}
                                label={option.label}
                              />
                            ))}
                          </Box>
                        }
                      />
                    </Box>

                    <Grid
                      justifyContent={"flex-end"}
                      alignItems="center"
                      sx={{ mt: 1 }}
                      container
                      spacing={1}
                    >
                      {!disabledInput && (
                        <Grid item>
                          <LoadingButton
                            onClick={handleSubmit(handleConfirmOrder)}
                            sx={styleSheet.placeOrderButton}
                            variant="contained"
                            loading={isLoadingForConfirm}
                            loadingPosition="start"
                            disabled={
                              selectedOrderPlaceButtom &&
                              selectedOrderPlaceButtom !==
                                EnumOrderPlaceButton.Confirm
                            }
                          >
                            Update
                          </LoadingButton>
                        </Grid>
                      )}
                      {!disabledInput &&
                        isStripeSettingExist &&
                        (!orderData.order.stripeInvoiceHostURL ||
                          !orderData.order.stripeInvoicePDFURL) &&
                        selectedPMOption !== EnumPaymentMethod.Prepaid && (
                          <Grid item>
                            {paymentLinks.show ? (
                              <Box className={"flex_center"} gap={1}>
                                <LoadingButton
                                  onClick={() =>
                                    handleCopyToClipBoard(
                                      paymentLinks.paymentUrl
                                    )
                                  }
                                  sx={{
                                    ...styleSheet.placeOrderButton,
                                    background: Colors.succes,
                                    border: "none",
                                  }}
                                  variant="contained"
                                >
                                  {"Copy Payment Url"}
                                </LoadingButton>
                                <LoadingButton
                                  onClick={() =>
                                    handleCopyToClipBoard(
                                      paymentLinks.paymentUrl
                                    )
                                  }
                                  sx={{
                                    ...styleSheet.placeOrderButton,
                                    background: Colors.danger,
                                    border: "none",
                                  }}
                                  variant="contained"
                                >
                                  {"Copy Invoice Pdf Url"}
                                </LoadingButton>
                                {paymentLinks.showCrossIcon && (
                                  <CrossIconButton
                                    onClick={() =>
                                      setPaymentLinks((prev) => ({
                                        ...prev,
                                        show: false,
                                      }))
                                    }
                                  />
                                )}
                              </Box>
                            ) : (
                              <LoadingButton
                                sx={styleSheet.sendInvoiceButton}
                                variant="outlined"
                                loading={isLoadingForConfirmAndSendInvoiceOrder}
                                loadingPosition="start"
                                onClick={handleSubmit(handleSendInvoice)}
                                disabled={
                                  selectedOrderPlaceButtom &&
                                  selectedOrderPlaceButtom !==
                                    EnumOrderPlaceButton.ConfirmAndHandleInvoice
                                }
                              >
                                Update And Get Payment Link
                              </LoadingButton>
                            )}
                          </Grid>
                        )}
                    </Grid>
                  </CustomColorLabelledOutline>
                </GridItem>
                <GridItem xs={12}>
                  {" "}
                  <CustomColorLabelledOutline
                    label={LanguageReducer?.languageType?.NOTE_TEXT}
                  >
                    <center>
                      <Typography
                        sx={{ fontSize: "14px !important", width: "40ch" }}
                      >
                        {finalNote
                          ? finalNote
                          : LanguageReducer?.languageType?.NOTE_DES_TEXT}
                      </Typography>
                    </center>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ marginTop: "22px" }}
                      size="small"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        // endAdornment={
                        //   <InputAdornment position="end">
                        //     <Button
                        //       onClick={(e) => {
                        //         setFinalNote(note);
                        //       }}
                        //       sx={styleSheet.sendNotButton}
                        //       variant="contained"
                        //     >
                        //       <SendIcon />
                        //     </Button>
                        //   </InputAdornment>
                        // }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        disabled={disabledInput}
                        onChange={(e) => {
                          setNote(e.target.value);
                        }}
                        value={note}
                        onFocus={handleFocus}
                        placeholder={`${LanguageReducer?.languageType?.DESCRIBE_TEXT} ${LanguageReducer?.languageType?.HERE_TEXT}...`}
                      />
                    </FormControl>
                  </CustomColorLabelledOutline>
                </GridItem>
                <GridItem xs={12}>
                  <CustomColorLabelledOutline
                    label={LanguageReducer?.languageType?.REMARKS_TEXT}
                  >
                    <center>
                      <Typography
                        sx={{ fontSize: "14px !important", width: "40ch" }}
                      >
                        {finalRemarks ? finalRemarks : null}
                      </Typography>
                    </center>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      sx={{ marginTop: "22px" }}
                      size="small"
                    >
                      <OutlinedInput
                        id="outlined-adornment-weight"
                        // endAdornment={
                        //   <InputAdornment position="end">
                        //     <Button
                        //       onClick={(e) => {
                        //         setFinalRemarks(remarks);
                        //       }}
                        //       style={{ color: "white" }}
                        //       sx={styleSheet.sendNotButton}
                        //     >
                        //       <SendIcon />
                        //     </Button>
                        //   </InputAdornment>
                        // }
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                          "aria-label": "weight",
                        }}
                        disabled={disabledInput}
                        onChange={(e) => {
                          setRemarks(e.target.value);
                        }}
                        value={remarks}
                        onFocus={handleFocus}
                        placeholder={`${LanguageReducer?.languageType?.REMARKS_TEXT} ${LanguageReducer?.languageType?.HERE_TEXT}...`}
                      />
                    </FormControl>
                  </CustomColorLabelledOutline>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </form>
      {open && (
        <AddProductModal
          open={open}
          setOpen={setOpen}
          productStocksForSelection={productStocksForSelection}
          setSelectedProducts={setSelectedProducts}
          selectedProducts={selectedProducts}
          productStations={productStations}
          setIsEditOrder={setIsEditOrder}
          isEditOrder={isEditOrder}
          orderData={orderData}
          setProductStocksForSelection={setProductStocksForSelection}
        />
      )}
      {/* {openLocationModal && ( */}
      <GoogleMapWithSearch
        open={openLocationModal}
        setOpen={setOpenLocationModal}
        setValue={setValue}
        setAutocomplete={setAutocomplete}
      />
      {/* )} */}
      {/* <FromLinkLocationModal
        open={openFromLinkLocationModal}
        setOpen={setOpenFromLinkLocationModal}
        setValue={setValue}
      /> */}
    </Box>
  );
}
export default EditFulFillableForm;
