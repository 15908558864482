import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  GetAllReasons,
  UpdateProductStockQuantityByReason,
} from "../../../api/AxiosInterceptors";
import { styleSheet } from "../../../assets/styles/style";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import { placeholders } from "../../../utilities/helpers/Helpers";
import RHFCustomAutocompleteField from "../../../.reUseableComponents/TextField/RHFCustomAutocompleteField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function EditInventoryModal({
  open,
  setOpen,
  inventoryItem,
  getAllProductInventory,
}) {
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [checkError, setCheckError] = useState(false);
  const [allReasons, setAllReasons] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
    control,
  } = useForm();
  const handleClose = () => {
    resetForm();
    setOpen(false);
  };
  const reasonValue = useWatch({
    name: "reason",
    control,
  });
  const quantityValue = useWatch({
    name: "quantity",
    control,
  });
  const [quantity, setQuantity] = useState(0);
  const updateData = async (data) => {
    // console.log(data);
    if (checkError) {
      errorNotification(
        LanguageReducer?.languageType
          .ENTER_QUANTITY_GREATER_THAN_AVAILABLE_QUANTITY_OR_DAMAGE_REPLACE_QUANTITY_TOAST
      );
    } else {
      let body = {
        productStockId: inventoryItem?.ProductStockId,
        reasonId: data?.reason?.id,
        quantity: parseFloat(data?.quantity),
        comment: data?.comment,
      };
      //console.log("body", body);
      try {
        const response = await UpdateProductStockQuantityByReason(body);
        // console.log("Update Quantity", response);
        successNotification(
          LanguageReducer?.languageType?.QUANTITY_SUCCESSFULLY_UPDATED_TOAST
        );

        resetForm();
        getAllProductInventory();
        handleClose();
      } catch (error) {
        console.error("Error in updating quantity:", error.response);
      }
    }
  };
  const resetForm = () => {
    setValue("quantity", "");
    setValue("comment", "");
    setValue("reason", "");
    setQuantity(0);
    setValue("reason", allReasons[0]);
  };
  const handleGetAllReasons = async () => {
    try {
      const response = await GetAllReasons();
      // console.log("response of api of reasons", response);
      setAllReasons(response.data.result);
    } catch (error) {
      console.error("Error fetching reasons:", error.response);
    }
  };
  useEffect(() => {
    const reasonId = getValues("reason")?.id;
    const quantityValue = parseFloat(getValues("quantity"));
    const availableQuantity = parseFloat(inventoryItem?.QuantityAvailable);
    const damageQuantity = parseFloat(inventoryItem?.QuantityDamage);

    if (reasonId === 1) {
      setQuantity(availableQuantity + quantityValue);
      setCheckError(false);
    } else if (reasonId === 2) {
      setQuantity(quantityValue);
      setCheckError(false);
    } else if (reasonId === 3) {
      if (!isNaN(quantityValue)) {
        if (quantityValue <= availableQuantity) {
          setQuantity(availableQuantity - quantityValue);
          setCheckError(false);
        } else {
          errorNotification(
            LanguageReducer?.languageType
              ?.ENTER_QUANTITY_GREATER_THAN_AVAILABLE_QUANTITY_TOAST
          );
          setCheckError(true);
        }
      }
    } else if (reasonId === 4) {
      if (!isNaN(quantityValue)) {
        if (quantityValue <= damageQuantity) {
          setQuantity(availableQuantity + quantityValue);
          setCheckError(false);
        } else {
          errorNotification(
            LanguageReducer?.languageType
              ?.ENTER_QUANTITY_GREATER_THAN_DAMAGE_REPLACE_QUANTITY_TOAST
          );
          setCheckError(true);
        }
      }
    }
    if (isNaN(quantityValue)) {
      setQuantity(0);
    }
  }, [reasonValue, quantityValue, getValues, quantity]);
  useEffect(() => {
    handleGetAllReasons();
  }, []);
  useEffect(() => {
    if (allReasons.length > 0) {
      setValue("reason", allReasons[0]);
    }
  }, [allReasons]);
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      scroll={"body"}
      onClose={handleClose}
      maxWidth="md"
      sx={styleSheet.modelMainClassWithImage}
      aria-describedby="alert-dialog-slide-description"
    >
      <form onSubmit={handleSubmit(updateData)}>
        <DialogTitle sx={styleSheet.allProductionHeading}>
          {LanguageReducer?.languageType?.UPDATE_QUANTITY_TEXT}
        </DialogTitle>
        <DialogContent sx={{ ...styleSheet.modelContentArea, width: "600px" }}>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container spacing={2} sx={{ mt: "5px" }}>
              <Grid item sm={12} sx={{ mt: "10px" }}>
                <Divider />
              </Grid>
              <Grid item sm={12}>
                <InputLabel required sx={styleSheet.inputLabel}>
                  {LanguageReducer?.languageType?.QUANTITY_TEXT}
                </InputLabel>
                <TextField
                  type="number"
                  id="quantity"
                  name="quantity"
                  placeholder={placeholders.quantity}
                  size="small"
                  fullWidth
                  variant="outlined"
                  {...register("quantity", {
                    required: {
                      value: true,
                      message:
                        LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                    },
                  })}
                  error={Boolean(errors.quantity)}
                  helperText={errors.quantity?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel required sx={styleSheet.inputLabel}>
                  {LanguageReducer?.languageType?.REASON_TEXT}
                </InputLabel>
                <RHFCustomAutocompleteField
                  name="reason"
                  control={control}
                  options={allReasons}
                  getOptionLabel={(option) => option?.text}
                  requiredError={
                    LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT
                  }
                  value={getValues("reason")}
                  errors={errors}
                  onChange={(event, newValue) => {
                    const resolvedId = newValue ? newValue : null;
                    setValue("reason", resolvedId);
                  }}
                  rules={{
                    required: {
                      value: true,
                      message:
                        LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                    },
                    validate: {
                      positive: (v) => parseInt(v.id) > 0,
                    },
                  }}
                  required
                />

                {/* <TextField
                  sx={{
                    "& .MuiInputLabel-root": {
                      color: "grey !important",
                    },
                  }}
                  label={
                    getValues("reason") === null || getValues("reason") === ""
                      ? ""
                      : getValues("reason")?.name
                  }
                  InputLabelProps={{ shrink: false }}
                  select
                  size="small"
                  id="reason"
                  name="reason"
                  value={watch("reason") || ""}
                  fullWidth
                  variant="outlined"
                  {...register("reason", {
                    required: {
                      value: true,
                      message:
                        LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                    },
                  })}
                  error={
                    !getValues("reason") === null || getValues("reason") === ""
                      ? Boolean(errors?.reason)
                      : false
                  }
                  helperText={
                    getValues("reason") === null || getValues("reason") === ""
                      ? errors?.reason?.message
                      : ""
                  }
                >
                  <MenuItem value={""} disabled>
                    {LanguageReducer?.languageType?.SELECT_REASON_TEXT}
                  </MenuItem>
                  {allReasons.map((Reason) => (
                    <MenuItem key={Reason.id} value={Reason}>
                      {Reason.text}
                    </MenuItem>
                  ))}
                </TextField> */}
              </Grid>
              {checkError !== true && (
                <>
                  <Grid item sm={12}>
                    <Typography
                      sx={{ fontWeight: "bold", color: "black" }}
                      variant="body1"
                    >
                      Previous Quantity:{" "}
                      <span style={{ color: "blue", fontWeight: "bold" }}>
                        {inventoryItem?.QuantityAvailable}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item sm={12}>
                    <Typography
                      sx={{ fontWeight: "bold", color: "black" }}
                      variant="body1"
                    >
                      New Quantity:{" "}
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        {quantity}
                      </span>
                    </Typography>
                  </Grid>
                </>
              )}

              <Grid item sm={12}>
                <InputLabel required sx={styleSheet.inputLabel}>
                  {LanguageReducer?.languageType?.COMMENT_TEXT}
                </InputLabel>
                <TextField
                  {...register("comment", {
                    required: {
                      value: true,
                      message:
                        LanguageReducer?.languageType?.FIELD_REQUIRED_TEXT,
                    },
                  })}
                  error={Boolean(errors.comment)}
                  helperText={errors.comment?.message}
                  type="text"
                  name="comment"
                  id="comment"
                  variant="outlined"
                  size="small"
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            sx={styleSheet.modalDismissButton}
            onClick={handleClose}
          >
            {"Dismiss"}
          </Button>
          <Button
            fullWidth
            variant="contained"
            sx={styleSheet.modalCarrierSubmitButton}
            type="submit"
          >
            {"Update Inventory"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default EditInventoryModal;
