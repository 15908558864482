import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DeleteConfirmationModal from "../../../../.reUseableComponents/Modal/DeleteConfirmationModal";
import { styleSheet } from "../../../../assets/styles/style";
import DeleteCarrierModal from "../../../../components/modals/carrierModals/DeleteCarrierModal";
import {
  ClipboardIcon,
  CodeBox,
  DeleteButton,
  DescriptionBox,
  DescriptionBoxWithChild,
  DisableButton,
  EnableButton,
  amountFormat,
  centerColumn,
  rightColumn,
} from "../../../../utilities/helpers/Helpers";
import {
  errorNotification,
  successNotification,
} from "../../../../utilities/toast";
import {
  DeleteProductById,
  DeleteStoreById,
  EnableProductById,
} from "../../../../api/AxiosInterceptors";
import UtilityClass from "../../../../utilities/UtilityClass";
import StatusBadge from "../../../../components/shared/statudBadge";
import Colors from "../../../../utilities/helpers/Colors";

function TasksList(props) {
  const { getAllProducts } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [infoModal, setInfoModal] = useState({
    open: false,
    loading: {},
    data: [],
  });
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteItemObject, setDeleteItemObject] = useState({});
  const [loadingStates, setLoadingStates] = useState(false);
  const [loadingEnableStates, setLoadingEnableStates] = useState({
    open: false,
    loading: {},
    data: [],
  });
  const handleDeleteConfirmation = (data) => {
    setDeleteItemObject(data);
    setOpenDelete(true);
  };
  const handleDelete = async () => {
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [deleteItemObject.ProductId]: true,
    }));
    try {
      let param = {
        ProductId: deleteItemObject.ProductId,
      };

      const response = await DeleteProductById(param);
      if (response.data?.isSuccess) {
        getAllProducts();
        successNotification("product deactivated successfully");
      } else {
        errorNotification("product deactivated unsuccessfull");
      }
    } catch (error) {
      console.error("Something went wrong", error.response);
      if (error?.response.data.errors) {
        UtilityClass.showErrorNotificationWithDictionary(
          error?.response?.data?.errors
        );
      }
    } finally {
      setLoadingStates((prevStates) => ({
        ...prevStates,
        [deleteItemObject.ProductId]: false,
      }));
      setOpenDelete(false);
      setDeleteItemObject({});
      setLoadingStates(false);
    }
  };
  const handleEnableProduct = async (data) => {
    try {
      let param = {
        ProductId: data.ProductId,
      };
      setLoadingEnableStates((prev) => ({
        ...prev,
        loading: { [data.ProductId]: true },
      }));
      const response = await EnableProductById(param);
      if (response.data?.isSuccess) {
        getAllProducts();
        successNotification("product activated successfully");
      } else {
        errorNotification("product activated unsuccessfull");
      }
    } catch (error) {
      console.error("Something went wrong", error.response);
      if (error?.response.data.errors) {
        UtilityClass.showErrorNotificationWithDictionary(
          error?.response?.data?.errors
        );
      }
    } finally {
      setLoadingEnableStates((prev) => ({
        ...prev,
        loading: { [data.ProductId]: false },
      }));
    }
  };
  const customRowStyle = {
    height: "10px", // Set the desired row height here
  };
  const getRowClassName = (params) => {
    for (let i = 0; i < products?.length; i++) {
      if (params.row.Active != true) return "active-row"; // CSS class name for active rows
    }
    return "";
  };
  const handleEditClick = (productData) => {
    setInfoModal((prev) => ({
      ...prev,
      loading: { [productData.ProductId]: true },
    }));

    const data = {
      products: productData,
    };

    navigate("/edit-products", { state: { data } });
    setInfoModal((prev) => ({
      ...prev,
      loading: { [productData.ProductId]: false },
    }));
  };
  const navigate = useNavigate();
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const { products, isGridLoading } = props;

  const columns = [
    {
      field: "ProductName",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.TITLE_TEXT}
        </Box>
      ),
      minWidth: 240,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Avatar
                src={params.row.FeatureImage}
                sx={{ width: 30, height: 30, fontSize: "13px" }}
              >
                {params.row.ProductName[0]}
              </Avatar>
              <Box display={"flex"}>
                <CodeBox
                  title={params.row.ProductName}
                  // onClick={(e) => {
                  //   handleEditClick(params.row);
                  // }}
                  hasColor={false}
                />
                <DescriptionBox description={params.row.Description} />
              </Box>
            </Stack>
          </Box>
        );
      },
    },
    {
      field: "SKU",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.SKU_TEXT}
        </Box>
      ),
      // ...centerColumn,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {infoModal.loading[params.row.ProductId] ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <CodeBox
                  // onClick={(e) => {
                  //   handleEditClick(params.row);
                  // }}
                  title={params.row.SKU}
                  hasColor={false}
                />
                {params.row.SKU && <ClipboardIcon text={params.row.SKU} />}
              </>
            )}
          </>
        );
      },
    },
    {
      field: "ProductCategoryName",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.CATEGORY_NAME_TEXT}
        </Box>
      ),
      minWidth: 120,
      flex: 1,
    },
    {
      field: "StoreName",
      headerName: <Box sx={{ fontWeight: "600" }}> {"Store Name"}</Box>,
      minWidth: 160,
      flex: 1,
    },
    {
      field: "QuantityAvailable",
      ...centerColumn,
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {/* {LanguageReducer?.languageType?.QUANTITY_AVAILABLE_TEXT} */}
          {"Qty Available"}
        </Box>
      ),
      width: 160,
      renderCell: ({ row }) => {
        return (
          <>
            <Box sx={{ fontWeight: "bold" }} mr={1}>
              {row.QuantityAvailable}{" "}
            </Box>
            {` in ${row.VarientCount} varient(s)`}
            <DescriptionBoxWithChild>
              <TableContainer>
                <Table sx={{ minWidth: 275 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          padding: "5px",
                          width: "110px",
                        }}
                        align="left"
                      >
                        SKU
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          padding: "5px",
                          width: "110px",
                        }}
                        align="left"
                      >
                        Station
                      </TableCell>

                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                        align="right"
                      >
                        Price
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                        align="center"
                      >
                        Qty
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.ProductStocks?.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        style={customRowStyle}
                      >
                        <TableCell
                          sx={{
                            padding: "7px",
                            fontSize: "11px",
                            width: "110px",
                          }}
                          align="left"
                        >
                          {/* <CodeBox title={row?.SKU} /> */}
                          <Box>
                            <Stack direction={"column"}>
                              <Box>{row?.SKU}</Box>
                              <Box>{row?.VarientOption}</Box>
                            </Stack>
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "7px",
                            fontSize: "11px",
                            width: "90px",
                          }}
                          align="left"
                        >
                          {row?.StationName}
                        </TableCell>

                        <TableCell
                          sx={{
                            padding: "7px",
                            width: "50px",
                            fontSize: "11px",
                          }}
                          align="right"
                        >
                          {amountFormat(row?.Price)}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "7px",
                            width: "50px",
                            fontSize: "11px",
                          }}
                          align="center"
                        >
                          <CodeBox title={row?.QuantityAvailable || "0"} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DescriptionBoxWithChild>
          </>
        );
      },
    },
    {
      field: "Weight",
      headerName: <Box sx={{ fontWeight: "600" }}> {"Weight"}</Box>,
      minWidth: 60,
      flex: 1,
      ...centerColumn,
    },
    {
      field: "PurchasePrice",
      headerName: <Box sx={{ fontWeight: "600" }}> {"Purchase Price"}</Box>,
      minWidth: 100,
      flex: 1,
      ...rightColumn,
      renderCell: ({ row }) => {
        return <Box>{amountFormat(row.PurchasePrice)} </Box>;
      },
    },
    {
      field: "Sale Price",
      headerName: <Box sx={{ fontWeight: "600" }}> {"Sale Price"}</Box>,
      minWidth: 80,
      flex: 1,
      ...rightColumn,
      renderCell: ({ row }) => {
        return <Box>{amountFormat(row.Price)} </Box>;
      },
    },
    {
      ...centerColumn,
      field: "Status",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.STATUS_TEXT}
        </Box>
      ),
      width: 80,

      renderCell: (params) => {
        let isActive = params.row.Active;
        let title = isActive ? "Active" : "InActive";
        return (
          <Box>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Box>
                <StatusBadge
                  title={title}
                  color={isActive == false ? "#fff;" : "#fff;"}
                  bgColor={isActive === false ? Colors.danger : Colors.succes}
                />

                {/* <Typography>{params.row.ProductStockStatus || ""}</Typography> */}
              </Box>
            </Stack>
          </Box>
        );
      },
    },
    
  ];
  return (
    <Box sx={styleSheet.allOrderTable}>
      <DataGrid
        loading={isGridLoading}
        rowHeight={40}
        headerHeight={40}
        sx={{
          fontFamily: "'Lato Regular', 'Inter Regular', 'Arial' !important",
          fontSize: "12px",
          fontWeight: "500",
        }}
        getRowId={(row) => row.ProductId}
        rows={products}
        columns={columns}
        disableSelectionOnClick
        pageSize={10}
        rowsPerPageOptions={[10,20,30]}
        getRowClassName={getRowClassName}
      />
      <Menu
        anchorEl={anchorEl}
        id="power-search-menu"
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box sx={{ width: "180px" }}>
          <List disablePadding>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setOpen(true);
                  setAnchorEl(null);
                }}
              >
                <ListItemText
                  primary={LanguageReducer?.languageType?.VIEW_TEXT}
                />
              </ListItemButton>
            </ListItem>
            <ListItem
              onClick={() => {
                setOpenUpdate(true);
                setAnchorEl(null);
              }}
              disablePadding
            >
              <ListItemButton onClick={handleEditClick}>
                <ListItemText
                  primary={LanguageReducer?.languageType?.EDIT_TEXT}
                />
              </ListItemButton>
            </ListItem>
            {/* <ListItem
              onClick={() => {
                setAnchorEl(null);
                navigate("/create-order");
              }}
              disablePadding
            >
              <ListItemButton>
                <ListItemText primary={LanguageReducer?.languageType?.PRINT_TEXT} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={LanguageReducer?.languageType?.EDIT_TEXT} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemText primary={LanguageReducer?.languageType?.DEBERIF_TEXT} />
              </ListItemButton>
            </ListItem> */}
          </List>
        </Box>
      </Menu>
      <DeleteConfirmationModal
        open={openDelete}
        setOpen={setOpenDelete}
        loading={false}
        handleDelete={handleDelete}
        heading={"Are you sure to disable this item?"}
        message={
          "After this action the item will be disabled immediately but you can undo this action anytime."
        }
        buttonText={"Disable"}
      />
      {/* <BatchOutScan open={open} setOpen={setOpen} {...props} />
      <UpdateOutScan open={openUpdate} setOpen={setOpenUpdate} {...props} /> */}
    </Box>
  );
}
export default TasksList;
