import { Box, MenuItem, MenuList, Popover } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import DataGridComponent from "../../../.reUseableComponents/DataGrid/DataGridComponent.js";
import DataGridHeader from "../../../.reUseableComponents/DataGridHeader/DataGridHeader.js";
import {
  GetStoresForSelection,
  SaleChannelOrderPostProcessor,
} from "../../../api/AxiosInterceptors.js";
import { styleSheet } from "../../../assets/styles/style.js";
import SaleChannelSyncModel from "../../../components/modals/orderModals/SaleChannelSyncModel.js";
import UtilityClass from "../../../utilities/UtilityClass.js";
import {
  ActionButton,
  ActionButtonCustom,
  DataGridHeaderBox,
  amountFormat,
  centerColumn,
  pagePadding,
  rightColumn,
} from "../../../utilities/helpers/Helpers.js";
import {
  errorNotification,
  successNotification,
  warningNotification,
} from "../../../utilities/toast/index.js";

export default function SaleChannelOrderPage() {
  const initialFilterFields = {
    loading: false,
    show: false,
    startDate: null,
    endDate: null,
  };
  const [allOrders, setAllOrders] = useState([]);
  const [filterModal, setFilterModal] = useState(initialFilterFields);
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [stores, setStores] = React.useState([]);

  const resetRowRef = useRef(false);
  const getOrdersRef = useRef([]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  let getAllStores = async () => {
    let res = await GetStoresForSelection();
    console.log("GetAllStores", res.data);
    if (res.data.result !== null) {
      setStores(res.data.result);
    }
  };



  // columns
  const columns = [
    {
      field: "orderNumber",
      headerName: <DataGridHeaderBox title={"Order No."} />,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ textAlign: "center", cursor: "pointer" }} disableRipple>
            <>
              <Box sx={{ fontWeight: "bold" }}>{params.row?.orderNumber}</Box>
            </>
          </Box>
        );
      },
    },
    {
      field: "orderType",
      headerName: <DataGridHeaderBox title={"Order Type"} />,
      flex: 2,
    },
    {
      field: "store",
      headerName: <DataGridHeaderBox title={"Store"} />,
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{ textAlign: "center" }}
            disableRipple
          >
            <>
              <Box sx={{ fontWeight: "bold" }}>{params.row?.storeName}</Box>
              <Box sx={{ fontWeight: "bold" }}>
                {params.row?.customerServiceNo}
              </Box>
              <Box noWrap={false}>{params.row?.shopDomain}</Box>
            </>
          </Box>
        );
      },
    },

    {
      field: "name",
      headerName: <DataGridHeaderBox title={"name"} />,
      minWidth: 120,
      flex: 2,
      align: "center",

      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{ textAlign: "center" }}
            disableRipple
          >
            <Box>
              {params.row?.shippingAddress?.first_name + " "}
              {params.row?.shippingAddress?.last_name}
            </Box>
          </Box>
        );
      },
    },
    {
      field: "address",
      headerName: <DataGridHeaderBox title={"Address"} />,
      flex: 2,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={{ textAlign: "center" }}
            disableRipple
          >
            <>
              <Box
                noWrap={false}
              >{`${params.row?.shippingAddress?.address1} ,${params.row?.shippingAddress?.city} ,${params.row?.shippingAddress?.country}`}</Box>
            </>
          </Box>
        );
      },
    },
    {
      field: "phone",
      headerName: <DataGridHeaderBox title={"Phone"} />,
      flex: 2,
      renderCell: (params) => {
        return params.row?.shippingAddress?.phone;
      },
    },
    {
      ...centerColumn,
      field: "orderDate",
      headerName: <DataGridHeaderBox title={"Order Date"} />,
      flex: 2,
      renderCell: (params) => {
        return UtilityClass.convertUtcToLocal(params.row.orderDate);
      },
    },
    {
      ...centerColumn,
      field: "weight",
      headerName: <DataGridHeaderBox title={"Weight"} />,
      flex: 2,
    },
    {
      ...rightColumn,
      field: "vat",
      headerName: <DataGridHeaderBox title={"VAT"} />,
      flex: 2,
      renderCell: ({row}) => {
        return amountFormat(row.vat)
      },
    },
    {
      ...rightColumn,
      field: "amount",
      headerName: <DataGridHeaderBox title={"Amount"} />,
      flex: 2,
      renderCell: ({row}) => {
        return amountFormat(row.amount)
      },
    },
  ];
  useEffect(() => {
    getAllStores();
  }, []);
  const [openCreateModel, setOpenCreateModel] = useState(false);

  const handleCreateReturnReport = () => {
    let selectedTrNos = getOrdersRef.current;
    if (selectedTrNos.length > 0) {
      console.log(selectedTrNos);
      let dataForIds = allOrders?.list?.find(
        (x) => x.orderId == selectedTrNos[0]
      );
      let param = {
        orderIds: selectedTrNos.toString(),
        saleChannelConfigId: !dataForIds?.saleChannelConfigId
          ? null
          : dataForIds?.saleChannelConfigId,
        saleChannelLookupId: dataForIds?.saleChannelLookupId,
        storeId: dataForIds?.storeId,
      };
      SaleChannelOrderPostProcessor(param)
        .then((res) => {
          if (!res.data.isSuccess) {
            UtilityClass.showErrorNotificationWithDictionary(res.data.errors);
          } else {
            successNotification("Order created successfully");

            ////////////
            //remove created rows
            let data = allOrders?.list.filter(
              (x) => !selectedTrNos.includes(x.orderId)
            );
            let filterdOrder = {
              list: data,
              TotalCount: allOrders?.TotalCount,
            };
            setAllOrders(filterdOrder);
            ///////////

            resetRowRef.current = true;
          }
        })
        .catch((e) => {
          errorNotification(
            LanguageReducer?.languageType
              ?.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN_TOAST
          );
          console.log("e", e);
        });
    } else {
      warningNotification(
        LanguageReducer?.languageType?.MUST_SELECT_SINGLE_ROW_WARNING_TOAST
      );
    }

    setAnchorEl(null);
  };
  const [openFetch, setOpenFetch] = useState();
  return (
    <Box sx={styleSheet.pageRoot}>
      <Box sx={{ padding: pagePadding }}>
        <DataGridHeader tabs={false}>
          <Box className={"flex_center"} gap={1}>
            {allOrders?.list?.length > 0 && (
              <ActionButton
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              />
            )}
            <ActionButtonCustom
              onClick={(event) => {
                setOpenFetch(true);
              }}
              label={"Sync Order"}
              width="120px"
            />
          </Box>
        </DataGridHeader>
        <DataGridComponent
          columns={columns}
          rows={allOrders}
          loading={filterModal.loading}
          getRowId={(row) => row.orderId}
          getOrdersRef={getOrdersRef}
          resetRowRef={resetRowRef}
          getRowHeight={() => "auto"}
          checkboxSelection={true}
        />
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuList
          sx={{
            minWidth: "162px !important",
          }}
          id={id}
          autoFocusItem
        >
          <MenuItem onClick={(event) => handleCreateReturnReport()}>
            Create Order
          </MenuItem>
        </MenuList>
      </Popover>

      {openFetch && (
        <SaleChannelSyncModel
          resetRowRef={resetRowRef}
          setAllOrders={setAllOrders}
          allStores={stores}
          orderNosData={getOrdersRef.current}
          open={openFetch}
          setOpen={setOpenFetch}
        />
      )}
    </Box>
  );
}
