import React from "react";
import { styleSheet } from "../../assets/styles/style";
import { LoadingButton } from "@mui/lab";

export default function ModalButtonComponent(props) {
  const { title, onClick, loading, type, bg } = props;
  return (
    <LoadingButton
      type={type}
      fullWidth
      loading={loading}
      variant="contained"
      sx={{
        ...styleSheet.modalCarrierSubmitButton,
        borderBottomRightRadius: "20px !important",
        marginLeft: "0px !important",
        background:
          bg ||
          "radial-gradient(100% 5102.04% at 100% 100%, #563AD5 0%, #442CAC 100%)",
      }}
      onClick={onClick}
    >
      {title}
    </LoadingButton>
  );
}
