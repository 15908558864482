import { FileUploadOutlined } from "@mui/icons-material";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SelectComponent from "../../../.reUseableComponents/TextField/SelectComponent";
import {
  CreateOrder,
  ExcelExportAddressEntitiesByType,
  GetOrderFileByOrderTypeId,
  GetProductStocksForSelection,
  GetStoresForSelection,
} from "../../../api/AxiosInterceptors";
import { getAllCountryFunc } from "../../../apiCallingFunction";
import { styleSheet } from "../../../assets/styles/style";
import EditFullfilableOrderDetailsModal from "../../../components/modals/orderModals/EditFullfilableOrderDetailsModal";
import EditRegularOrderDetailsModal from "../../../components/modals/orderModals/EditRegularOrderDetailsModal";
import PlaceOrderModal from "../../../components/modals/orderModals/PlaceOrderModal";
import UtilityClass from "../../../utilities/UtilityClass";
import { EnumOptions, EnumOrderType } from "../../../utilities/enum";
import Colors from "../../../utilities/helpers/Colors";
import {
  ActionButtonCustom,
  ActionButtonEdit,
  DescriptionBoxWithChild,
  GridItem,
  StyledTooltip,
  amountFormat,
  centerColumn,
  fetchMethod,
  placeholders,
  useMapAutocompleteSetter,
  usePagination,
} from "../../../utilities/helpers/Helpers";
import { useGetAddressSchema } from "../../../utilities/helpers/addressSchema";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";

function UploadOrders(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [orderData, setOrderData] = useState([]);
  const [orderType, setOrderType] = useState();
  const [selectedRowIndex, setSelectedRowIndex] = useState();
  const [placingOrder, setPlacingOrder] = useState(false);
  const [storesForSelection, setStoresForSelection] = useState([]);
  const [errorsList, setErrorsList] = useState([]);
  const navigate = useNavigate();
  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  const [allCountries, setAllCountries] = useState([]);
  const [allRegions, setAllRegions] = useState([]);
  const [allCities, setAllCities] = useState([]);
  const [autocomplete, setAutocomplete] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    control,
    unregister,
  } = useForm();

  useWatch({
    name: "store",
    control,
  });
  useWatch({
    name: "station",
    control,
  });

  let getStoresForSelection = async () => {
    let res = await GetStoresForSelection();
    setStoresForSelection(res.data.result);
  };

  let getAllCountry = async () => {
    let data = await getAllCountryFunc();
    if (data?.length > 0) setAllCountries(data);
  };

  useMapAutocompleteSetter(
    autocomplete,
    allCountries,
    allRegions,
    allCities,
    setValue
  );

  useEffect(() => {
    getStoresForSelection();
    getAllCountry();
  }, []);

  useEffect(() => {
    if (selectedData) {
      setEditOpen(true);
    }
  }, [selectedData]);

  const downloadSample = async (id, c_id) => {
    let res = await GetOrderFileByOrderTypeId(id, c_id);
    const link = document.createElement("a");
    link.href = res.data.result.url;
    link.download = id === 1 ? "Regular.xlsx" : "Fulfillable.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleCreateOrder = async () => {
    if (errorsList.length > 0) {
      errorNotification("Please fix errors before proceed");
    } else {
      await createOrder();
    }
  };
  const createOrder = async () => {
    let params = [];
    setErrorsList([]);

    for (let i = 0; i < orderData.length; i++) {
      let orderItems = [];
      for (let j = 0; j < orderData[i].orderItems.length; j++) {
        orderItems.push({
          productId: orderData[i].orderItems[j].productId,
          productStockId: orderData[i].orderItems[j].productStockId,
          price: orderData[i].orderItems[j].price,
          description: orderData[i].orderItems[j].description,
          remarks: orderData[i].orderItems[j].remarks,
          quantity: orderData[i].orderItems[j].quantity,
          discount: orderData[i].orderItems[j].discount,
        });
      }
      params.push({
        storeId: orderData[i].storeId,
        orderTypeId: orderData[i].orderTypeId,
        orderDate: orderData[i].orderDate,
        description: orderData[i].description,
        remarks: orderData[i].remarks,
        amount: orderData[i].amount,
        cShippingCharges: orderData[i].cShippingCharges,
        paymentStatusId: orderData[i].paymentStatusId,
        weight: orderData[i].weight,
        itemValue: orderData[i].itemValue,
        orderRequestVia: orderData[i].orderRequestVia,
        paymentMethodId: orderData[i].paymentMethodId,
        stationId: orderData[i].stationId,
        discount: orderData[i].discount,
        vat: orderData[i].vat,
        refNo: orderData[i].refNo,
        orderNote: orderData[i].orderNote,
        orderAddress: {
          customerName: orderData[i].orderAddress.customerName,
          email: orderData[i].orderAddress.email,
          mobile1: orderData[i].orderAddress.mobile1,
          mobile2: orderData[i].orderAddress.mobile2,
          countryId: orderData[i].orderAddress.country,
          provinceId: orderData[i].orderAddress.province,
          pinCodeId: orderData[i].orderAddress.pinCode,
          stateId: orderData[i].orderAddress.state,
          cityId: orderData[i].orderAddress.city,
          areaId: orderData[i].orderAddress.area,
          streetAddress: orderData[i].orderAddress.streetAddress,
          latitude: orderData[i].orderAddress.latitude,
          longitude: orderData[i].orderAddress.longitude,
        },
        orderItems: orderItems,
      });
    }
    let param1 = { orderList: params };
    setPlacingOrder(true);
    CreateOrder(param1)
      .then((res) => {
        if (!res?.data?.isSuccess) {
          for (let i = 0; i < res?.data?.errorCombined.length; i++) {
            errorNotification(res?.data?.errorCombined[i]);
          }

          errorNotification(res?.data?.customErrorMessage);
        } else {
          successNotification("Orders created successfully");
          navigate("/orders-dashboard");
        }
      })
      .catch((e) => {
        console.log("e", e);
        let msg = [];
        let count = 0;
        for (const key in e.response?.data?.errorCombined) {
          if (e.response?.data?.errorCombined.hasOwnProperty(key)) {
            const errorMessage = e.response?.data?.errorCombined[key];
            console.log(errorMessage);
            errorNotification(errorMessage);
            msg.push({
              Row: parseInt(key) + 1,
              Msg: errorMessage,
              IsSuccessed: false,
            });
          } else {
            msg.push({ Row: count, Msg: "errorMessage", IsSuccessed: true });
          }
          count++;
        }
        console.log("msgmsg", msg);
        setErrorsList(msg);
        errorNotification("Something went wrong");
      })
      .finally((e) => {
        setPlacingOrder(false);
      });
  };
  const getOrderTypeId = () => {
    let orderTypeId = EnumOrderType.Regular;
    if (orderData && orderData.length > 0) {
      orderTypeId = orderData[0].orderTypeId;
    }
    return orderTypeId;
  };
  const [stockLoading, setStockLoading] = useState(false);
  const [filterdProductStock, setFilterdProductStock] = useState([]);

  const getProductStockByStationAndStore = async (data) => {
    setStockLoading(true);
    let res = await GetProductStocksForSelection(data.stationId, data?.storeId);
    let preloadedProducts = [];
    setStockLoading(false);

    if (res.data.result && res.data.result.length > 0) {
      data?.orderItems.forEach((item, i) => {
        let value = res.data.result?.find(
          (x) => x.ProductStockId == item.productStockId
        );
        if (value) {
          value["orderItemId"] = item.orderItemId;
          value["Price"] = item.price;
          value["newQuantity"] = item.quantity;
          value["discount"] = item.discount;
          preloadedProducts.push(value);
        }
      });
      setFilterdProductStock(preloadedProducts);
    }
  };
  const handleEdit = (row) => {
    row.amount = row.actualAmmount;
    setSelectedData(row);
  };
  const columns = [
    {
      field: "refNo",
      headerName: <Box sx={{ fontWeight: "600" }}>{" Ref No."}</Box>,
      renderCell: (params) => {
        return params.row.refNo;
      },
      flex: 1,
    },
    {
      field: "orderTypeId",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.ORDER_TYPE_TEXT}
        </Box>
      ),
      renderCell: (params) => {
        return params.row.orderTypeId == 1 ? "Regular" : "Fulfillable";
      },
      flex: 1,
    },
    {
      field: "customerName",
      renderCell: (params) => {
        return params?.row?.orderAddress?.customerName;
      },
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.CUSTOMER_TEXT}
        </Box>
      ),
      flex: 1,
    },

    {
      field: "mobile1",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.MOBILE_NUMBER_TEXT}
        </Box>
      ),
      renderCell: (params) => {
        return params?.row?.orderAddress?.mobile1;
      },
      flex: 1,
    },
    {
      field: "Product",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.PRODUCT_TEXT}
        </Box>
      ),
      flex: 1,
      ...centerColumn,
      renderCell: (params) => {
        return (
          <Box>
            {params?.row?.orderItems?.length}

            <DescriptionBoxWithChild
              onClick={() => getProductStockByStationAndStore(params.row)}
            >
              <TableContainer>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          padding: "5px",
                          // width: "110px",
                        }}
                        align="left"
                      >
                        Sku
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          padding: "5px",
                        }}
                      >
                        Qty
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          padding: "5px",
                          // width: "150px",
                        }}
                        align="right"
                      >
                        Discount
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          fontSize: "11px",
                          padding: "5px",
                          // width: "150px",
                        }}
                        align="right"
                      >
                        Price
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {stockLoading ? (
                    <Box>{placeholders.loading}</Box>
                  ) : (
                    <TableBody>
                      {filterdProductStock?.map((item) => (
                        <TableRow
                          key={1}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: "7px",
                              fontSize: "11px",
                            }}
                            align="left"
                          >
                            {item?.SKU}
                          </TableCell>
                          <TableCell
                            sx={{ padding: "7px", fontSize: "11px" }}
                            align="right"
                          >
                            {item?.newQuantity}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "7px",
                              fontSize: "11px",
                              // width: "150px",
                            }}
                            align="right"
                          >
                            {item?.discount}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "7px",
                              fontSize: "11px",
                              // width: "150px",
                            }}
                            align="right"
                          >
                            {amountFormat(item?.Price)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </DescriptionBoxWithChild>
          </Box>
        );
      },
    },
    {
      field: "remarks",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.REMARKS_TEXT}
        </Box>
      ),
      flex: 1,
    },
    {
      field: "amount",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.AMOUNT_TEXT}
        </Box>
      ),
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            <Box sx={{ textAlign: "center" }}>{row.amount}</Box>{" "}
          </>
        );
      },
    },
    {
      field: "description",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.DESCRIPTION_TEXT}
        </Box>
      ),
      valueGetter: (params) => `${params.row.description || ""}`,
      flex: 1,
    },
    {
      field: "orderAddress",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.ADDRESS_TEXT}
        </Box>
      ),
      sortable: false,
      renderCell: (params) => {
        return params.row.orderAddress.streetAddress;
      },
      flex: 1,
    },

    {
      field: "Action",
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.ACTION_TEXT}
        </Box>
      ),
      flex: 1,
      renderCell: (params) => {
        const index = errorsList.findIndex((error) => {
          return error.Row == params.row.index && error.IsSuccessed == false;
        });
        return (
          <Box>
            <ActionButtonEdit
              onClick={(e) => {
                setSelectedRowIndex(params.row.index);
                setOrderType(params.row.orderTypeId);
                handleEdit(params.row);
              }}
            />
            {index != -1 && (
              <StyledTooltip title={errorsList[index].Msg}></StyledTooltip>
            )}
          </Box>
        );
      },
    },
  ];
  const getRowClassName = (params) => {
    for (let i = 0; i < errorsList.length; i++) {
      if (
        params.row.index == errorsList[i].Row &&
        errorsList[i].IsSuccessed === false
      )
        return "active-row"; // CSS class name for active rows
    }
    return "";
  };
  const { currentPage, pageSize, handlePageChange, handlePageSizeChange } =
    usePagination(0, 10);

  const { addressSchemaSelectData, handleSetSchema } = useGetAddressSchema();

  useWatch({
    name: "country",
    control,
  });
  useWatch({
    name: "region",
    control,
  });
  useWatch({
    name: "city",
    control,
  });

  return (
    <Box sx={styleSheet.pageRoot}>
      <div style={{ padding: "10px" }}>
        <GridItem mb={1} textAlign={"right"}>
          <LoadingButton
            onClick={() => {
              navigate("/orders-dashboard");
            }}
            sx={styleSheet.placeOrderButton}
            variant="contained"
          >
            Order Dashboard
          </LoadingButton>
        </GridItem>
        <Card sx={styleSheet.createOrderCard} variant="outlined">
          <Grid container spacing={2} sx={{ mt: "5px" }}>
            <Grid item md={3} sm={12} xs={12}>
              <InputLabel required sx={styleSheet.inputLabel}>
                {LanguageReducer?.languageType?.SELECT_COUNTRY_TEXT}
              </InputLabel>
              <SelectComponent
                name="country"
                control={control}
                options={allCountries}
                isRHF={true}
                required={true}
                optionLabel={EnumOptions.COUNTRY.LABEL}
                optionValue={EnumOptions.COUNTRY.VALUE}
                {...register("country", {
                  required: {
                    value: true,
                  },
                })}
                value={getValues("country")}
                onChange={(event, newValue) => {
                  const resolvedId = newValue ? newValue : null;
                  handleSetSchema("country", resolvedId, setValue, unregister);
                }}
                errors={errors}
              />
            </Grid>
            <Grid item md={12} sm={6} sx={{ display: "flex" }} gap={2}>
              <ActionButtonCustom
                label={LanguageReducer?.languageType?.IMPORT_TEXT}
                onClick={() => {
                  setOpen(true);
                }}
                startIcon={<FileUploadOutlined />}
                disabled={getValues("country")?.countryId ? false : true}
              />
              <ActionButtonCustom
                label={LanguageReducer?.languageType?.SAMPLE_REGULAR_TEXT}
                onClick={() => {
                  downloadSample(1, getValues("country")?.countryId);
                }}
                startIcon={<FileDownloadOutlinedIcon />}
                disabled={getValues("country")?.countryId ? false : true}
              />
              <ActionButtonCustom
                label={LanguageReducer?.languageType?.SAMPLE_FULFILLABLE_TEXT}
                onClick={() => {
                  downloadSample(2, getValues("country")?.countryId);
                }}
                startIcon={<FileDownloadOutlinedIcon />}
                disabled={getValues("country")?.countryId ? false : true}
              />
              {addressSchemaSelectData?.map((entity, entity_i) => {
                const prevKey =
                  entity_i === 0
                    ? "country"
                    : addressSchemaSelectData[entity_i - 1].key;
                return (
                  <ActionButtonCustom
                    background={Colors.succes}
                    label={`Download ${entity.label}`}
                    onClick={async () => {
                      const { response } = await fetchMethod(() =>
                        ExcelExportAddressEntitiesByType(
                          getValues("country")?.countryId,
                          entity.key
                        )
                      );
                      UtilityClass.downloadExcel(
                        response,
                        `${entity.label} File`
                      );
                    }}
                    startIcon={<FileDownloadOutlinedIcon />}
                  />
                );
              })}
            </Grid>
            <Grid
              sm={12}
              md={12}
              display={"flex"}
              flexDirection={"row"}
              alignSelf={"end"}
              style={{ paddingTop: "10px" }}
            >
              <ActionButtonCustom
                loading={placingOrder}
                sx={
                  orderData.length === 0
                    ? {
                        ...styleSheet.disabledButton,
                        marginLeft: "auto",
                      }
                    : {
                        ...styleSheet.orderButton,
                        marginLeft: "auto",
                      }
                }
                variant="contained"
                disabled={orderData.length === 0}
                onClick={() => {
                  handleCreateOrder();
                }}
                label={"Upload Orders "}
              />
            </Grid>
          </Grid>
        </Card>
        <br />
        <Box
          sx={{
            ...styleSheet.allOrderTable,
            "& .MuiDataGrid-root": {
              borderRadius: "8px 8px 8px 8px !important",
            },
          }}
        >
          <DataGrid
            rowHeight={40}
            headerHeight={40}
            sx={{
              fontFamily: "'Lato Regular', 'Inter Regular', 'Arial' !important",
              fontSize: "12px",
              fontWeight: "500",
            }}
            getRowId={(row) => `${row.orderDate} ${row.description} `}
            rows={orderData}
            columns={columns}
            disableSelectionOnClick
            pagination
            page={currentPage}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 15, 25]}
            paginationMode="client"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            getRowClassName={getRowClassName}
          />

          <Menu
            anchorEl={anchorEl}
            id="power-search-menu"
            open={Boolean(anchorEl)}
            onClose={() => {
              setAnchorEl(null);
            }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <Box sx={{ width: "200px" }}>
              <List disablePadding>
                <ListItem
                  onClick={() => {
                    setEditOpen(true);
                    setAnchorEl(null);
                  }}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <DisplaySettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Edit Order" />
                  </ListItemButton>
                </ListItem>
              </List>
            </Box>
          </Menu>
          <PlaceOrderModal
            open={open}
            setOpen={setOpen}
            setOrderData={setOrderData}
            storesForSelection={storesForSelection}
            setErrorsList={setErrorsList}
            errorsList={errorsList}
            countryId={getValues("country")?.countryId}
          />
          {selectedData ? (
            orderType === 2 ? (
              <EditFullfilableOrderDetailsModal
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                open={editOpen}
                setOpen={setEditOpen}
                storesForSelection={storesForSelection}
                orderData={orderData}
                setOrderData={setOrderData}
                selectedRowIndex={selectedRowIndex}
                setSelectedRowIndex={setSelectedRowIndex}
                errorsList={errorsList}
                setErrorsList={setErrorsList}
              />
            ) : (
              <EditRegularOrderDetailsModal
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                open={editOpen}
                setOpen={setEditOpen}
                storesForSelection={storesForSelection}
                orderData={orderData}
                setOrderData={setOrderData}
                selectedRowIndex={selectedRowIndex}
                setSelectedRowIndex={setSelectedRowIndex}
                errorsList={errorsList}
                setErrorsList={setErrorsList}
              />
            )
          ) : null}
        </Box>
      </div>
    </Box>
  );
}
export default UploadOrders;
