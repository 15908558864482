import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ConfirmSignUpUser,
  Login,
  ResendConfirmationCode,
} from "../../../api/AxiosInterceptors";
import {
  errorNotification,
  successNotification,
} from "../../../utilities/toast";
import { styleSheet } from "../../../assets/styles/style";
import setLoginCookies from "../loginScreen/setLoginCookies";
import { getThisKeyCookie } from "../../../utilities/cookies";
import UtilityClass from "../../../utilities/UtilityClass";
import { Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function OTPPage(props) {
  const [otp, setOtp] = useState();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const location = useLocation();
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  let navigate = useNavigate();
  const resendOTP = () => {
    ResendConfirmationCode({ userName: location.state.userName })
      .then((res) => {
        //  successNotification(LanguageReducer?.languageType?.OTP_SENT_SUCCESSFULLY_TOAST);
        setMinutes(1);
        setSeconds(0);
      })
      .catch((e) => {
        setErrorMessage(
          LanguageReducer?.languageType?.UNABLE_TO_SEND_OTP_USER_TOAST
        );
        console.log("e", e);
      });
  };

  const confirmSignUpUser = async () => {
    if (!otp) {
      setErrorMessage(LanguageReducer?.languageType?.PLEASE_ENTER_OTP_TOAST);
      return;
    }
    const body = {
      userName: location.state?.userName,
      code: otp,
    };
    setIsSubmit(true);
    ConfirmSignUpUser(body)
      .then((res) => {
        console.log("res", res);
        if (res.data.isSuccess) {
          var cookieVal = getThisKeyCookie("U2FsdGVkX1");
          const body = UtilityClass.decryptData(cookieVal);

          Login(body)
            .then((res) => {
              if (res.data.isSuccess) {
                setLoginCookies(res);
                if (getThisKeyCookie("access_token")) navigate("/orders-dashboard");
              }
            })
            .catch((e) => {
              setErrorMessage(
                LanguageReducer?.languageType?.INVALID_USER_NAME_PASSWORD_TOAST
              );
              console.log("e", e);
            });

          // successNotification(LanguageReducer?.languageType?.USER_VERIFIED_SUCCESSFULLY_TOAST);
          // const timer = setTimeout(() => {
          //     navigate("/");
          // }, 2000);
          // return () => clearTimeout(timer);
        } else {
          setErrorMessage(res.data.errors.CodeMismatch[0]);
        }
      })
      .catch((e) => {
        setErrorMessage(
          LanguageReducer?.languageType?.UNABLE_TO_VERIFY_USER_TOAST
        );
        console.log("e", e);
      })
      .finally((e) => {
        setIsSubmit(false);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const LanguageReducer = useSelector((state) => state.LanguageReducer);
  return (
    <Box
      sx={{
        height: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "25px",
      }}
    >
      <Paper
        elevation={6}
        TransitionComponent={Transition}
        sx={styleSheet.cardMainClass}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={styleSheet.outScanHeading}>
          {LanguageReducer?.languageType?.VERIFY_OTP_TEXT}
        </DialogTitle>
        <DialogContent sx={styleSheet.cardContentArea}>
          {errorMessage && errorMessage != "" && (
            <Box sx={styleSheet.integrationCardText}>
              <Alert
                variant="outlined"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorMessage("");
                    }}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                }
              >
                {errorMessage}
              </Alert>
            </Box>
          )}
          <OtpInput
            inputStyle={{
              width: "3rem",
              height: "3rem",
              margin: "20px .8rem",
              fontSize: "1rem",
              borderRadius: 4,
              border: "2px solid rgba(0,0,0,0.3)",
            }}
            value={otp}
            onChange={setOtp}
            numInputs={6}
            separator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
          <Typography sx={styleSheet.integrationCardText}>
            <b>A verification code has been sent to your email. </b>
          </Typography>
          {seconds > 0 || minutes > 0 ? (
            <Typography sx={styleSheet.integrationCardDes}>
              {LanguageReducer?.languageType?.TIME_REMAINING_TEXT}
              {minutes < 10 ? `0${minutes}` : minutes} :{" "}
              {seconds < 10 ? `0${seconds}` : seconds}
              <Button
                disabled={seconds > 0 || minutes > 0}
                onClick={resendOTP}
                variant="text"
              >
                {LanguageReducer?.languageType?.RESEND_OTP_TEXT}
              </Button>
            </Typography>
          ) : (
            <Typography sx={styleSheet.integrationCardDes}>
              {LanguageReducer?.languageType?.DID_NOT_RECEIVE_CODE_TEXT}
              <Button
                disabled={seconds > 0 || minutes > 0}
                onClick={resendOTP}
                variant="text"
              >
                {LanguageReducer?.languageType?.RESEND_OTP_TEXT}
              </Button>
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            sx={styleSheet.modalSubmitButton}
            onClick={() => {
              confirmSignUpUser();
            }}
            loading={isSubmit}
          >
            {isSubmit ? (
              <CircularProgress sx={{ color: "white" }} />
            ) : (
              "Confirm"
            )}
          </Button>
        </DialogActions>
      </Paper>
    </Box>
  );
}
export default OTPPage;
