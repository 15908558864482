import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  Avatar,
  Box,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ConfirmationIcon from "../../assets/images/confirmationIcon.png";
import { errorNotification, successNotification } from "../../utilities/toast";
import { styleSheet } from "../../assets/styles/style";
import { LoadingButton } from "@mui/lab";
import { red } from "../../utilities/helpers/Helpers";
import ModalComponent from "./ModalComponent";
import ModalButtonComponent from "../Buttons/ModalButtonComponent";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function DeleteConfirmationModal(props) {
  const LanguageReducer = useSelector((state) => state.LanguageReducer);

  let {
    open,
    setOpen,
    loading = false,
    handleDelete = () => {},
    buttonColor,
    heading = LanguageReducer?.languageType?.CONFIRMATION_DELETE_TOAST,
    message = LanguageReducer?.languageType
      ?.SUPPORT_CONFIRMATION_STORE_DELETE_TOAST,
    messageDetails = "",
    buttonText = "Delete",
  } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ModalComponent
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      actionBtn={
        <ModalButtonComponent
          title={buttonText}
          loading={loading}
          bg={buttonColor}
          onClick={() => {
            handleDelete();
            if (props?.setIsDeletedConfirm) {
              props?.setIsDeletedConfirm(true);
            }
          }}
        />
      }
    >
      <Box display={"flex"} gap={1}>
        <Box width={100} height={100}>
          <Box
            component={"img"}
            width={"100%"}
            height={"100%"}
            sx={{ objectFit: "contain" }}
            src={ConfirmationIcon}
          />
        </Box>
        <Box alignSelf={"center"}>
          <Typography variant="h3">{heading}</Typography>
          <Typography variant="h5" fontWeight={300} mt={0.25}>
            {message + messageDetails}
          </Typography>
        </Box>
      </Box>
    </ModalComponent>
  );
}
export default DeleteConfirmationModal;
