import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Paper,
  Slide,
  DialogActions,
  Button,
  Stack,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { EnumOrderType } from "../../../utilities/enum";
import { DataGrid } from "@mui/x-data-grid";
import { styleSheet } from "../../../assets/styles/style";
import {
  ClipboardIcon,
  CodeBox,
  amountFormat,
  rightColumn,
} from "../../../utilities/helpers/Helpers";
export default function OrderItemDetailedModal(props) {
  const { open, onClose, data } = props;
  const LanguageReducer = useSelector((state) => state.LanguageReducer);

  const columns = [
    {
      field: "ProductName",
      headerName: <Box sx={{ fontWeight: "600" }}>Product Name</Box>,
      maxWidth: 150,
      flex: 1,
      hide: data[0]?.OrderTypeId == EnumOrderType.Regular && true,
      renderCell: (params) => {
        return (
          <Stack direction={"column"} disableRipple>
            <>
              <Box>{params.row.ProductName}</Box>
            </>
          </Stack>
        );
      },
    },
    {
      field: "ProductStockSku",
      headerName: <Box sx={{ fontWeight: "600" }}>Product Name</Box>,
      maxWidth: 150,
      flex: 1,
      hide: data[0]?.OrderTypeId == EnumOrderType.Regular && true,
      renderCell: (params) => {
        return (
          <Stack flexDirection={"row"} alignItems={"center"}>
            <Stack sx={{ textAlign: "center" }} direction={"column"}>
              <CodeBox hasColor={false} title={params.row.ProductStockSku} />
            </Stack>
            <ClipboardIcon text={params.row.ProductStockSku} />
          </Stack>
        );
      },
    },

    {
      field: "OrderItemDescription",
      headerName: <Box sx={{ fontWeight: "600" }}>Description</Box>,
      minWidth: 200,
      flex: 1,
    },
    {
      field: "OrderItemQuantity",
      headerAlign: "center",
      align: "center",
      headerName: <Box sx={{ fontWeight: "600" }}> Quantity</Box>,
      maxWidth: 90,
      flex: 1,
      hide: data[0]?.OrderTypeId == EnumOrderType.Regular && true,
      renderCell: (params) => (
        <Box>
          <CodeBox hasColor={false} title={params.row.OrderItemQuantity} />
        </Box>
      ),
    },
    {
      field: "OrderItemDiscount",
      ...rightColumn,
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.DISCOUNT_TEXT}
        </Box>
      ),
      maxWidth: 90,
      flex: 1,
      hide: data[0]?.OrderTypeId == EnumOrderType.Regular && true,
      renderCell: (params) => <>{amountFormat(params.row.OrderItemDiscount)}</>,
    },

    {
      field: "Price",
      ...rightColumn,
      headerName: (
        <Box sx={{ fontWeight: "600" }}>
          {" "}
          {LanguageReducer?.languageType?.AMOUNT_TEXT}
        </Box>
      ),
      maxWidth: 120,
      flex: 1,
      hide: data[0]?.OrderTypeId == EnumOrderType.Regular && true,
      renderCell: (params) => <>{amountFormat(params.row.Price)}</>,
    },
  ];

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={onClose}
      keepMounted
      maxWidth="lg"
      sx={styleSheet.modelMainClassWithImage}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={styleSheet.outScanHeading}>{"Order Items"}</DialogTitle>
      <DialogContent>
        <Box height={400} pt={1}>
          <DataGrid
            rowHeight={40}
            headerHeight={40}
            sx={{
              fontFamily: "'Lato Regular', 'Inter Regular', 'Arial' !important",
              fontSize: "12px",
              fontWeight: "500",
            }}
            rows={data}
            getRowId={(row) => row.OrderItemId}
            columns={columns}
            disableSelectionOnClick
            pageSize={10}
            rowsPerPageOptions={[10]}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          sx={styleSheet.modalDismissButton}
          onClick={onClose}
        >
          {LanguageReducer?.languageType?.DISMISS_TEXT}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
